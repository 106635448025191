import { HttpService } from '@app/core/services/http';
import { Injectable } from '@angular/core';
import { endpoints } from './account.endpoints';
import { handleResponse } from '@app/utils';
import { GetMenbersPayload, ResetAccountPayload, ResetPasswordPayload } from './account.payload';
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpService) {}

  getNotificationSettings = (accountId: string) => this.http.get({ url: `${endpoints.getNotificationSettingsUrl}/${accountId}` });

  updateNotificationSettings = (accountId: string, body: any) =>
    this.http.post({ url: `${endpoints.updateNotificationSettingsUrl}/${accountId}`, body });

  resetPassword = (params: ResetPasswordPayload) => {
    const url = `${endpoints.retrievePasswordUrl}/${params.id || ''}`;
    return this.http.post({ url, body: { EmailAddress: params.emailAddress } }).pipe(handleResponse());
  };

  userDelete = (accountId: string) => this.http.remove({ url: `${endpoints.userDelete}/${accountId}` }).pipe(handleResponse());

  getMembers = (params: GetMenbersPayload) => this.http.get({ url: `${endpoints.getMembers}`, params }).pipe(handleResponse());

  resetAccount = (body: ResetAccountPayload) => this.http.post({ url: endpoints.resetAccount, body }).pipe(handleResponse());
}
