import { EnsureModuleLoadedOnceGuard } from '@app/core/guards';
import { RootStoreModule } from './store/store';
import { AuthInterceptor } from './interceptors';
import { WINDOW_PROVIDERS } from './providers';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule({
  imports: [RootStoreModule, ToastrModule.forRoot()],
  exports: [],
  providers: [WINDOW_PROVIDERS, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() targetModule: CoreModule) {
    super(targetModule);
  }
}
