import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import commonReducer, { COMMON_KEY } from './common.slice';
import { CommonEffects } from './common.effects';

@NgModule({
  imports: [EffectsModule.forFeature([CommonEffects]), StoreModule.forFeature(COMMON_KEY, commonReducer)],
  providers: [],
})
export class CommonStoreModule {}
