import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import contestReducer, { CONTEST_KEY } from './contest.slice';
import { ContestEffects } from './contest.effects';

@NgModule({
  imports: [EffectsModule.forFeature([ContestEffects]), StoreModule.forFeature(CONTEST_KEY, contestReducer)],
  providers: [],
})
export class ContestStoreModule {}
