import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { Species, SpeciesDropdown } from './species.models';
import { endpoints } from './species.endpoints';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpeciesService {
  constructor(private http: HttpService) {}

  getSpeciesDetail = (params: any): Observable<object> =>
    this.http.get({ url: `${endpoints.getSpeciesDetails}/${params.speciesId}` }).pipe(handleResponse(Species));

  getSpeciesList = (): Observable<object> => this.http.get({ url: endpoints.getSpeciesList }).pipe(handleResponse(SpeciesDropdown));
}
