import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import postsReducer, { POSTS_KEY } from './posts.slice';
import { PostsEffects } from './posts.effects';

@NgModule({
  imports: [EffectsModule.forFeature([PostsEffects]), StoreModule.forFeature(POSTS_KEY, postsReducer)],
  providers: [],
})
export class PostsStoreModule {}
