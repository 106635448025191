import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './users.state';
import { request, success, failure, cleanState, push, update, collections } from '@app/utils/common';
import _ from 'lodash';
export default {
  getUserDetails: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getUserDetailsRequest: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getUserDetails, payload, payload.userId),
  getUserDetailsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => collections(state.getUserDetails, payload, payload.id),
  getUserDetailsFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) =>
    failure(state.getUserDetails, error, key),

  getCurrentUser: (state: StateInterface, _action: PayloadAction<any>) => request(state.getCurrentUser),
  getCurrentUserSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    success(state.getCurrentUser, payload);
    collections(state.getUserDetails, payload, payload.id);
  },
  getCurrentUserFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getCurrentUser, payload),

  followUser: (state: StateInterface, { payload }: PayloadAction<any>) => {
    update(state.getUserDetails, { hasPendingRequest: true }, payload.id);
    update(state.getMembers, { HasPendingRequest: true }, { Id: payload.id });
  },
  followUserFailure: (state: StateInterface, { payload }: PayloadAction<any>) => {
    update(state.getMembers, { HasPendingRequest: false }, { Id: payload.id });
  },

  unfollowUser: (state: StateInterface, { payload }: PayloadAction<any>) => {
    update(state.getUserDetails, { isConnected: false }, payload.id);
    update(state.getMembers, { IsConnected: false }, { Id: payload.id });
  },
  unfollowUserFailure: (state: StateInterface, { payload }: PayloadAction<any>) => {
    update(state.getMembers, { IsConnected: true }, { Id: payload.id });
  },

  updateUserProfile: (state: StateInterface, _action: PayloadAction<any>) => request(state.updateUserProfile, {}),
  updateUserProfileSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.updateUserProfile, payload),
  updateUserProfileFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.updateUserProfile, payload),

  getMembers: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getMembers, payload),
  getMembersSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => push(state.getMembers, payload),
  getMembersFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getMembers, payload),

  getUserFollowers: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getUserFollowers, payload),
  getUserFollowersSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getUserFollowers, payload),
  getUserFollowersFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getUserFollowers, payload),

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
