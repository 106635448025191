import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import catchReducer, { CATCH_KEY } from './catch.slice';
import { CatchEffects } from './catch.effects';

@NgModule({
  imports: [EffectsModule.forFeature([CatchEffects]), StoreModule.forFeature(CATCH_KEY, catchReducer)],
  providers: [],
})
export class CatchStoreModule {}
