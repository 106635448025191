import reducers from './account.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './account.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const ACCOUNT_KEY = 'account-store';

const accountSlice = createSlice({ name: ACCOUNT_KEY, initialState, reducers });
const accountSelector: SelectorInterface = buildSelector(accountSlice, initialState);
const accountActions = accountSlice.actions;
const accountState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default accountSlice.reducer;
export { ACCOUNT_KEY, accountActions, accountSelector, accountState };
