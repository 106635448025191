import reducers from './lure.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './lure.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const LURE_KEY = 'lure-store';

const lureSlice = createSlice({ name: LURE_KEY, initialState, reducers });
const lureSelector: SelectorInterface = buildSelector(lureSlice, initialState);
const lureActions = lureSlice.actions;
const lureState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default lureSlice.reducer;
export { LURE_KEY, lureActions, lureSelector, lureState };
