import { ResponseApi, initResult } from '../../../utils/models';
export interface StateInterface {
  getCatchMap?: ResponseApi<any>;
  getSpotMap?: ResponseApi<any>;
  getCatcPublichMap?: ResponseApi<any>;
  getSpotPublicMap?: ResponseApi<any>;
  getWaterwayMap?: ResponseApi<any>;
  navigateData?: any;
  zoom?: number;
  getDefaultMapStyleInfo?: any;
}

export interface SelectorInterface {
  getCatchMap?: any;
  getSpotMap?: any;
  getCatcPublichMap?: any;
  getSpotPublicMap?: any;
  getWaterwayMap?: any;
  navigateData?: any;
  zoom?: any;
  getDefaultMapStyleInfo?: any;
}

export const initialState: StateInterface = {
  getCatchMap: initResult,
  getSpotMap: initResult,
  getCatcPublichMap: initResult,
  getSpotPublicMap: initResult,
  getWaterwayMap: initResult,
  navigateData: null,
  zoom: 2,
  getDefaultMapStyleInfo: null,
};
