export const STATE_KEY = 'posts';

export const RECIPIENT_WATER_TYPE = 3;

export enum BasePostTypes {
  Post = 'Post',
  Spot = 'Spot',
  Catch = 'Catch',
  Photo = 'Photo',
}

const COMMON_POST_TYPE = BasePostTypes.Post;
const CATCH_POST_TYPE = BasePostTypes.Catch;
const SPOT_POST_TYPE = BasePostTypes.Spot;
const NORMAL_POST_TYPE = 'Normal';
const PHOTO_POST_TYPE = 'Photo';
const COMMENT_POST_TYPE = 'Comment';
const MESSAGE_POST_TYPE = 'Message';

const NORMAL_POST_TYPE_ID = 0;
const COMMON_POST_TYPE_ID = 0;
const PHOTO_POST_TYPE_ID = 1;
const CATCH_POST_TYPE_ID = 2;
const SPOT_POST_TYPE_ID = 3;
const COMMENT_POST_TYPE_ID = 4;
const MESSAGE_POST_TYPE_ID = 5;

export const postTypes = {
  NORMAL_POST_TYPE,
  COMMON_POST_TYPE,
  PHOTO_POST_TYPE,
  CATCH_POST_TYPE,
  SPOT_POST_TYPE,
  COMMENT_POST_TYPE,
  MESSAGE_POST_TYPE,
};

export const postTypesIds = {
  [NORMAL_POST_TYPE]: NORMAL_POST_TYPE_ID,
  [COMMON_POST_TYPE]: COMMON_POST_TYPE_ID,
  [PHOTO_POST_TYPE]: PHOTO_POST_TYPE_ID,
  [CATCH_POST_TYPE]: CATCH_POST_TYPE_ID,
  [SPOT_POST_TYPE]: SPOT_POST_TYPE_ID,
  [COMMENT_POST_TYPE]: COMMENT_POST_TYPE_ID,
  [MESSAGE_POST_TYPE]: MESSAGE_POST_TYPE_ID,
};

export const postTypesById = {
  [COMMON_POST_TYPE_ID]: COMMON_POST_TYPE,
  [PHOTO_POST_TYPE_ID]: PHOTO_POST_TYPE,
  [CATCH_POST_TYPE_ID]: CATCH_POST_TYPE,
  [SPOT_POST_TYPE_ID]: SPOT_POST_TYPE,
  [COMMENT_POST_TYPE_ID]: COMMENT_POST_TYPE,
  [MESSAGE_POST_TYPE_ID]: MESSAGE_POST_TYPE,
};
