import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import mediaReducers, { MEDIA_KEY } from '@app/core/store/media/media.slice';

@NgModule({
  imports: [EffectsModule.forFeature([]), StoreModule.forFeature(MEDIA_KEY, mediaReducers)],
  exports: [],
  providers: [],
})
export class MediaStoreModule {}
