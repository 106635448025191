import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { postsActions, postsSelector } from './posts.slice';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { PostService } from '@app/core/features/posts/post.service';
import { commonActions } from '../common/common.slice';
import _ from 'lodash';
import { usersActions } from '../users/users.slice';
import { checkCollection } from '@app/utils';

@Injectable()
export class PostsEffects {
  createPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.createPost),
      mergeMap(action =>
        this.postService.createPost(action.payload).pipe(
          map((data: any) => {
            this.baseHelper.showSuccessActionToastr('Save the Post successfully!');
            return postsActions.createPostSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, postsActions.createPostFailure)),
        ),
      ),
    ),
  );

  editPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.editPost),
      mergeMap(action =>
        this.postService.editPost(action.payload).pipe(
          map((data: any) => {
            this.baseHelper.showSuccessActionToastr('Edit the Post successfully!');
            return postsActions.editPostSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, postsActions.editPostFailure)),
        ),
      ),
    ),
  );

  getFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.getFeed),
      mergeMap(action =>
        this.postService.getFeed(action.payload).pipe(
          map((data: any) => postsActions.getFeedSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, postsActions.getFeedFailure)),
        ),
      ),
    ),
  );

  getFeedNoLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.getFeedNoLoading),
      mergeMap(action =>
        this.postService.getFeed(action.payload).pipe(
          map((data: any) => postsActions.getFeedNoLoadingSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getFeedByExtent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.getFeedByExtent),
      mergeMap(action =>
        this.postService.getFeedByExtent(action.payload).pipe(
          map((data: any) => postsActions.getFeedByExtentSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, postsActions.getFeedByExtentFailure)),
        ),
      ),
    ),
  );

  getPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.getPost),
      withLatestFrom(this.store.select(postsSelector.getPost)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, payload) && payload)),
      map(payload => (payload ? postsActions.getPostRequest(payload) : commonActions.noAction())),
    ),
  );

  getPostRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.getPostRequest),
      mergeMap(action =>
        this.postService.getPost(action.payload).pipe(
          map((data: any) => postsActions.getPostSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, postsActions.getPostFailure, action.payload)),
        ),
      ),
    ),
  );

  likePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.likePost),
      mergeMap(action =>
        this.handleLikePostFuns(action).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(postsActions.likePostFailure(action.payload))),
        ),
      ),
    ),
  );

  likeComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.likeComment),
      mergeMap(action =>
        this.handleLikeCommentFuns(action).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(postsActions.likeCommentFailure(action.payload))),
        ),
      ),
    ),
  );

  createComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.createComment),
      mergeMap(action =>
        this.postService.createComment(_.pick(action.payload, ['message', 'postId'])).pipe(
          map(() => postsActions.createCommentSuccess(action.payload)),
          catchError(() => of(postsActions.createCommentFailure(action.payload))),
        ),
      ),
    ),
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.deleteComment),
      mergeMap(({ payload }: any) =>
        this.postService.deleteComment(payload.postId, _.pick(payload, ['commentId'])).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(postsActions.deleteCommentFailure(payload))),
        ),
      ),
    ),
  );

  deletePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.deletePost),
      mergeMap(action =>
        this.postService.deletePost(action.payload).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(postsActions.deletePostFailure(action.payload))),
        ),
      ),
    ),
  );

  reportPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.reportPost),
      mergeMap(action =>
        this.postService.report(action.payload).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(commonActions.noAction())),
        ),
      ),
    ),
  );

  afterFollowUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.followUser),
      mergeMap(({ payload }) => of(payload.postId ? postsActions.afterFollowUser(payload) : commonActions.noAction())),
    ),
  );

  afterUnfollowUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.unfollowUser),
      mergeMap(({ payload }) => of(payload.postId ? postsActions.afterUnfollowUser(payload) : commonActions.noAction())),
    ),
  );

  needGetPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        postsActions.createCommentSuccess,
        postsActions.createCommentFailure,
        postsActions.deleteCommentFailure,
        postsActions.likePostFailure,
        postsActions.likeCommentFailure,
      ),
      mergeMap(action => of(postsActions.getPostRequest(action.payload.postId || action.payload.id))),
    ),
  );

  constructor(
    private postService: PostService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}

  handleLikeCommentFuns = action => {
    const { unlikeComment, likeComment } = this.postService;
    const { likedByUser, postId, commentId } = action.payload;
    return likedByUser ? unlikeComment({ postId, commentId }) : likeComment({ postId, commentId });
  };

  handleLikePostFuns = action => {
    const { unlikePost, likePost } = this.postService;
    const { id, likedByUser } = action.payload;
    return likedByUser ? unlikePost(id) : likePost(id);
  };
}
