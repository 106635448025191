import { ObjectWithStringValues } from '@app/core/models';

export const endpoints: ObjectWithStringValues = {
  catchMap: '/Catch/Map',
  spotMap: '/Spot/Map',
  catchPublicMap: '/Catch/PublicMap',
  spotPublicMap: '/Spot/PublicMap',
  waterwayMap: '/Waterway/SearchByExtent',
  mapStyle: '/Mapbox/GetStyleFile',
};
