import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import waterwaysReducer, { WATERWAYS_KEY } from './waterways.slice';
import { WaterwaysEffects } from './waterways.effects';

@NgModule({
  imports: [EffectsModule.forFeature([WaterwaysEffects]), StoreModule.forFeature(WATERWAYS_KEY, waterwaysReducer)],
  providers: [],
})
export class WaterwaysStoreModule {}
