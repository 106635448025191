import { EntitiesCollection } from '@app/core/features/base';
import { convertToCommonDateTime } from '@app/utils';
import { User } from '@app/core/features/users/user-details.models';
import { notificationsTypes } from '@app/constants';

export type NotificationsCollection = EntitiesCollection<Notification>;

export class NotificationsCount {
  commentsNotificationsCount: number;
  friendRequestsCount: number;
  messagesCount: number;
  waterwayNotificationsCount: number;
  likeNotificationCount: number;

  constructor(response: any) {
    this.commentsNotificationsCount = response.CommentNotificationCount;
    this.friendRequestsCount = response.FriendRequestCount;
    this.messagesCount = response.InboxCount;
    this.waterwayNotificationsCount = response.WaterwayNotificationCount;
    this.likeNotificationCount = response.LikeNotificationCount;
  }
}

export class Notification {
  id: string;
  notificationType: number;
  requestDateTime: string;
  isNew: boolean;
  feedItemId: string;
  feedItemType: number;
  friendRequestId: string;
  fromUser: User;
  waterwayName: string;
  isFollowRequest: boolean;
  isOutdated: boolean;
  isMulti: false;

  constructor(response: any) {
    this.id = response.NotificationId;
    this.notificationType = response.NotificationType;
    this.requestDateTime = response.RequestDateTime && convertToCommonDateTime(response.RequestDateTime);
    this.isNew = response.IsNew;
    this.feedItemId = response.FeedItemId;
    this.feedItemType = response.FeedItemType;
    this.friendRequestId = response.FriendRequestId;
    this.waterwayName = response.WaterwayName;
    this.fromUser = response.FromUser && new User(response.FromUser);
    this.isFollowRequest = response.NotificationType === notificationsTypes.FOLLOW_REQUEST;
    this.isOutdated = false;
    this.isMulti = false;
  }
}

export interface MultiNotification {
  notificationType: number;
  isNew: boolean;
  feedItemId: string;
  feedItemType: number;
  isMulti: true;
  notifications: Notification[];
  usersCount: number;
  fromUser: User;
}
