import { getProfilePhotoUrl, getPostPhotoUrl } from '@app/utils';

export class ContestEntry {
  id: string;
  entityType: number;
  entityId: string;
  photoId: string;
  photo: string;
  authorId: string;
  authorName: string;
  authorLocation: string;
  authorProfilePhoto: string;
  likedByUser: boolean;
  description: string;
  likeCount: number;
  species: string;
  waterway: string;

  constructor(respone) {
    this.id = respone.Id;
    this.entityType = respone.EntityType;
    this.entityId = respone.EntityId;
    this.photoId = respone.PhotoId;
    this.photo = respone.PhotoId && getPostPhotoUrl(respone.PhotoId, 300);
    this.authorId = respone.AuthorId;
    this.authorName = respone.AuthorName;
    this.authorLocation = respone.AuthorLocation;
    this.authorProfilePhoto = getProfilePhotoUrl(respone.AuthorId, respone.AuthorProfilePhoto);
    this.likedByUser = respone.LikedByUser;
    this.description = respone.Description;
    this.likeCount = respone.LikeCount;
    this.species = respone.Species;
    this.waterway = respone.Waterway;
  }
}
