import { createSlice } from 'ngrx-slice';
import { initialState, MediaSelector } from '@app/core/store/media/media.state';
import reducers from '@app/core/store/media/media.reducers';
import { buildSelector } from '@app/utils';
import _ from 'lodash';

export const MEDIA_KEY = 'media-store';

export const mediaSlice = createSlice({ name: MEDIA_KEY, initialState, reducers });

export const mediaSelector: MediaSelector = buildSelector(mediaSlice, initialState);

export const mediaActions = mediaSlice.actions;

export const mediaState: MediaSelector = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default mediaSlice.reducer;
