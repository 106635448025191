import { Post } from '@app/core/features/posts/post.models';
import { User } from '@app/core/features/users/user-details.models';

export class SpotPublic {

  spotList: SpotList;

  feedDetailItem: Post;

  constructor(response: Record<string, any>) {
    if (!response) {
      this.spotList = new SpotList(null);
      this.feedDetailItem = new Post(null);
      return;
    }

    if (response.SpotList) {
      this.spotList = new SpotList(response.SpotList);
    }

    if (response.FeedDetailItem) {
      this.feedDetailItem = new Post(response.FeedDetailItem);
    }
  }

  static empty() {
    return new SpotPublic(null);
  }
}

export class SpotList {

  spotId: string;

  name: string;

  description: string;

  waterwayName: string;

  waterwayId: string;

  spotDateTime: string;

  owner: User;

  latitude?: number;

  longitude?: number;

  photoId: string;

  likeCount: number;

  likedByUser: boolean;

  commentCount: number;

  feedItemId: string;

  latestComment: any;

  commentedOnByUser: boolean;

  postedDateTime: string;

  privacyType: number;

  privacyTypeString: string;

  constructor(response: Record<string, any>) {

    if (!response) {
      return;
    }

    this.spotId = response.SpotId;
    this.name = response.Name;
    this.description = response.Description;
    this.waterwayName = response.WaterwayName;
    this.waterwayId = response.WaterwayId;
    this.spotDateTime = response.SpotDateTime;
    this.owner = new User(response.Owner);
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.photoId = response.PhotoId;
    this.likeCount = response.LikeCount;
    this.likedByUser = !!response.LikedByUser;
    this.commentCount = response.commentCount ?? 0;
    this.feedItemId = response.FeedItemId;
    this.latestComment = response.LatestComment;
    this.postedDateTime = response.PostedDateTime;
    this.commentedOnByUser = !!response.CommentedOnByUser;
    this.privacyType = response.PrivacyType;
    this.privacyTypeString = '';
  }

}


