import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './species.state';
import { request, success, failure, cleanState, collections, orderBy } from '@app/utils/common';
import _ from 'lodash';
export default {
  getSpecies: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getSpeciesRequest: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getSpecies, payload, payload.speciesId),
  getSpeciesSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => collections(state.getSpecies, payload, payload.id),
  getSpeciesFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) => failure(state.getSpecies, error, key),

  getSpeciesList: (state: StateInterface) => request(state.getSpeciesList),
  getSpeciesListSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    success(state.getSpeciesList, payload);
    orderBy(state.getSpeciesList, 'id');
  },
  getSpeciesListFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getSpeciesList, payload),

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
