import reducers from './waterways.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './waterways.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const WATERWAYS_KEY = 'waterways-store';

const waterwaysSlice = createSlice({ name: WATERWAYS_KEY, initialState, reducers });
const waterwaysSelector: SelectorInterface = buildSelector(waterwaysSlice, initialState);
const waterwaysActions = waterwaysSlice.actions;
const waterwaysState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default waterwaysSlice.reducer;
export { WATERWAYS_KEY, waterwaysActions, waterwaysSelector, waterwaysState};
