import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { User, Auth } from './auth.models';
import { LoadAuthPayload } from './auth.payload';
import { endpoints } from './auth.endpoints';
import { ADMIN_ACCOUNT_TYPE } from './auth.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpService, private localStorage: LocalStorage) {}

  signIn(body): Observable<object> {
    return this.http.post({ body, url: endpoints.signIn }).pipe(handleResponse(Auth));
  }

  signUp(body): Observable<object> {
    return this.http.post({ body, url: endpoints.signUp }).pipe(handleResponse(Auth));
  }

  getAuthFromStorage(): Observable<any> {
    return this.localStorage.getItem<Observable<LoadAuthPayload>>('auth');
  }

  setAuthToStorage(auth: LoadAuthPayload): void {
    this.localStorage.setItem('auth', auth).subscribe(() => {});
  }

  removeAuthFromStorage(): Observable<boolean> {
    return this.localStorage.removeItem('auth');
  }

  removeAuthFromStorageAsync(): void {
    this.localStorage.removeItem('auth').subscribe(() => {});
  }

  isAuthValid(auth: LoadAuthPayload): boolean {
    return Boolean(auth && auth.token && auth.user);
  }

  isAdmin(user: User): boolean {
    return Boolean(user.accountType === ADMIN_ACCOUNT_TYPE);
  }

  getLogInStatus(): Observable<boolean> {
    return this.getAuthFromStorage().pipe(map(this.handleAuthStateLoad.bind(this)));
  }

  private handleAuthStateLoad = (auth): boolean => {
    if (this.isAuthValid(auth)) {
      return true;
    }
    return false;
  };
}
