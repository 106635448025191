import { ResponseApi, initResult } from '../../../utils/models';
export interface StateInterface {
  resetPassword?: ResponseApi<any>;
  resetAccount?: ResponseApi<any>;
  userDelete?: ResponseApi<any>;
  getNotificationSettings?: ResponseApi<any>;
  updateNotificationSettings?: ResponseApi<any>;
}

export interface SelectorInterface {
  resetPassword?: any;
  resetAccount?: any;
  userDelete?: any;
  getNotificationSettings?: any;
  updateNotificationSettings?: any;
}

export const initialState: StateInterface = {
  resetPassword: initResult,
  resetAccount: initResult,
  userDelete: initResult,
  getNotificationSettings: initResult,
  updateNotificationSettings: initResult,
};
