import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { lureActions } from './lure.slice';
import { Store } from '@ngrx/store';
import { LureService } from '@app/core/features/lure/lure.service';

@Injectable()
export class LureEffects {
  getLureList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lureActions.getLureList),
      mergeMap(() =>
        this.lureService.getLureDropdownList().pipe(
          map((data: any) => lureActions.getLureListSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, lureActions.getLureList)),
        ),
      ),
    ),
  );

  constructor(
    private lureService: LureService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
