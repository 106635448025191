import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import speciesReducer, { SPECIES_KEY } from './species.slice';
import { SpeciesEffects } from './species.effects';

@NgModule({
  imports: [EffectsModule.forFeature([SpeciesEffects]), StoreModule.forFeature(SPECIES_KEY, speciesReducer)],
  providers: [],
})
export class SpeciesStoreModule {}
