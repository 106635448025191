type ConvertSchemaMethod = (o: any) => any;

export interface ConvertSchema {
  [key: string]: string | ConvertSchemaMethod;
}

export interface ResponseApi<T> {
  isLoading?: boolean;
  result?: T;
  collection?: CollectionResponseApi<T>;
  payload?: any;
  length?: number;
  error?: object;
}

export interface CollectionResponseApi<T> {
  [key: string]: ResponseApi<T>;
}

export const initResult: ResponseApi<any> = {
  isLoading: false,
  result: null,
  payload: null,
  error: null,
};

export const initCollection: ResponseApi<any> = {
  collection: {},
};
