import { ResponseApi, initResult } from '@app/utils';

export interface MediaState {
  uploadPhoto?: ResponseApi<any>;
  uploadPhotoSuccess?: ResponseApi<any>;
  uploadPhotoFailure?: ResponseApi<any>;
}

export interface MediaSelector {
  uploadPhoto?: any;
  uploadPhotoSuccess?: any;
  uploadPhotoFailure?: any;
}

export const initialState: MediaState = {
  uploadPhoto: initResult,
  uploadPhotoSuccess: initResult,
  uploadPhotoFailure: initResult,
};
