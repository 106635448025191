import { WINDOW } from '@app/core/providers';
import { Injectable, Inject } from '@angular/core';
import { REDIRECT_TO } from '@app/core/features/auth';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private location: Location = this.window.location;
  private redirectTo = REDIRECT_TO;

  constructor(
    @Inject(WINDOW) private window: Window,
  ) {}

  change(url: string): void {
    this.location.href = url;
  }

  replace(url: string): void {
    this.location.replace(url);
  }

  replaceWithParam(url: string, param): void  {
    this.location.replace(`${url}?${this.redirectTo}=${param}`);
  }
}
