import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import lureReducer, { LURE_KEY } from './lure.slice';
import { LureEffects } from './lure.effects';

@NgModule({
  imports: [EffectsModule.forFeature([LureEffects]), StoreModule.forFeature(LURE_KEY, lureReducer)],
  providers: [],
})
export class LureStoreModule {}
