import { getPostPhotoUrl } from '@app/utils';
export class Species {
  public id: string;
  public name: string;
  public photoId: string;
  public photo: string;
  public aliases: string;
  public mFAid: number;
  public description: string;

  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.photoId = response.PhotoId;
    this.photo = getPostPhotoUrl(response.PhotoId, 300);
    this.aliases = response.Aliases;
    this.aliases = response.MFAid;
    this.description = response.Description;
  }
}

export class SpeciesDropdown {
  public id: string;
  public value: string;
  public image: string;
  public imageId: string;

  constructor(response: any) {
    this.id = response.Id;
    this.value = response.Name;
    this.imageId = response.PhotoId;
    this.image = getPostPhotoUrl(response.PhotoId, 300);
  }
}
