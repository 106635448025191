import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './account.state';
import { request, success, failure, cleanState } from '@app/utils/common';
import _ from 'lodash';
import { ResetAccountPayload, ResetPasswordPayload } from '@app/core/features/account/account.payload';
export default {
  getNotificationSettings: (state: StateInterface, _action: PayloadAction<any>) => request(state.getNotificationSettings),
  getNotificationSettingsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) =>
    success(state.getNotificationSettings, payload),
  getNotificationSettingsFailure: (state: StateInterface, { payload }: PayloadAction<any>) =>
    failure(state.getNotificationSettings, payload),

  updateNotificationSettings: (state: StateInterface, _action: PayloadAction<any>) => request(state.updateNotificationSettings),
  updateNotificationSettingsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) =>
    success(state.updateNotificationSettings, payload),
  updateNotificationSettingsFailure: (state: StateInterface, { payload }: PayloadAction<any>) =>
    failure(state.updateNotificationSettings, payload),

  resetPassword: (state: StateInterface, _action: PayloadAction<ResetPasswordPayload>) => request(state.resetPassword),
  resetPasswordSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.resetPassword, payload),
  resetPasswordFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.resetPassword, payload),

  userDelete: (state: StateInterface, _action: PayloadAction<any>) => request(state.userDelete),
  userDeleteSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.userDelete, payload),
  userDeleteFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.userDelete, payload),

  resetAccount: (state: StateInterface, _action: PayloadAction<ResetAccountPayload>) => request(state.resetAccount),
  resetAccountSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.resetAccount, payload),
  resetAccountFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.resetAccount, payload),

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
