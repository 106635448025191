import { relativePaths } from '@app/constants';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocationService } from '@app/core/services/location/location.service';

@Injectable()
export class MobileDeviceGuard implements CanActivate {
  constructor(
    private deviceService: DeviceDetectorService,
    private locationService: LocationService,
  ) {}

  canActivate(): Observable<boolean> {
    if (this.deviceService.isMobile()) {
      this.locationService.replace(relativePaths.FISHING_MOBILE_APP);
      return of(false);
    } else {
      return of(true);
    }
  }
}
