import { getProfilePhotoUrl, getPostPhotoUrl, toPoundsAndOunces } from '@app/utils';

export class UserStats {
  friendCount: number;
  waterWayCount: number;
  catchCount: number;
  spotCount: number;
  likeCount: number;
  commentCount: number;
  commentedCount: number;
  likedCount: number;
  postCount: number;

  constructor(response: any) {
    this.friendCount = response.FriendCount;
    this.waterWayCount = response.WaterWayCount;
    this.catchCount = response.CatchCount;
    this.spotCount = response.SpotCount;
    this.likeCount = response.LikeCount;
    this.commentCount = response.CommentCount;
    this.commentedCount = response.CommentedCount;
    this.likedCount = response.LikedCount;
    this.postCount = response.PostCount;
  }
}

export class UsersProfileFavorites {
  species: object[];
  lures: object[];
  waterways: object[];
  speciesStrings: string[];
  lureStrings: string[];
  waterwayStrings: string[];
  speciesString: string;
  luresString: string;
  waterwaysString: string;

  constructor(response: any) {
    this.species = response.Species;
    this.lures = response.Lures;
    this.waterways = response.Waterways;
    this.speciesStrings = response.SpeciesStrings;
    this.lureStrings = response.LureStrings;
    this.waterwayStrings = response.WaterwayStrings;
    this.speciesString = response.SpeciesString;
    this.luresString = response.LuresString;
    this.waterwaysString = response.WaterwaysString;
  }
}

export class UsersFavorites {
  specie: string;
  lure: string;
  waterway: string;

  constructor(response: any) {
    this.specie = response.Specie;
    this.lure = response.Lure;
    this.waterway = response.Waterway;
  }
}

export class UsersWaterway {
  id: string;
  name: string;
  county: string;
  regionAbbr: string;
  state: string;
  isPremium: boolean;
  stateAbbr: string;
  latitude: string;
  longitude: string;

  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.county = response.County;
    this.regionAbbr = response.RegionAbbr;
    this.state = response.State;
    this.isPremium = response.IsPremium;
    this.stateAbbr = response.StateAbbr;
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
  }
}

export class User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  name: string;
  email: string;
  accountType: string;
  bio: any; // TODO: use relevant type
  birthday: string;
  businesses: number;
  catchCount: number;
  contactId: string;
  createdOn: string;
  city: string;
  emailAddress: string;
  facebookToken: string;
  friendCount: number;
  groups: number;
  hasPendingRequest: boolean;
  highlightReel: object[]; // TODO: use relevant type
  infusionSoftId: string;
  isBlocked: boolean;
  isConnected: boolean;
  isFreemium: boolean;
  isPremium: boolean;
  isSelf: boolean;
  mobile: boolean;
  profileFavorites: UsersProfileFavorites; // TODO: use relevant type
  profilePhotoId: string;
  profilePhoto: string;
  signUpDateTime: string;
  PremiumEndDate: string;
  spotCount: number;
  state: string; // TODO: check relevantion of that type
  stats: UserStats;
  waterwayCount: number;
  zip: string; // TODO: check relevantion of that type
  waterways: UsersWaterway[];
  biggestCatch: UserBiggestCatch;
  favorites: UsersFavorites;

  constructor(response: any) {
    this.id = response.Id;
    this.firstName = response.FirstName;
    this.lastName = response.LastName;
    this.fullName = `${response.FirstName} ${response.LastName}`;
    this.name = response.Name;
    this.email = response.Email;
    this.accountType = response.AccountType;
    this.bio = response.Bio;
    this.birthday = response.Birthday;
    this.businesses = response.Businesses;
    this.catchCount = response.CatchCount;
    this.contactId = response.ContactId;
    this.createdOn = response.CreatedOn;
    this.city = response.City;
    this.emailAddress = response.EmailAddress;
    this.facebookToken = response.FacebookToken;
    this.friendCount = response.FriendCount;
    this.groups = response.Groups;
    this.hasPendingRequest = response.HasPendingRequest;
    this.highlightReel = response.HighlightReel;
    this.infusionSoftId = response.infusionSoftId;
    this.isBlocked = response.IsBlocked;
    this.isConnected = response.IsConnected;
    this.isFreemium = response.IsFreemium;
    this.isPremium = response.IsPremium;
    this.isSelf = response.IsSelf;
    this.mobile = response.Mobile;
    this.profileFavorites = response.ProfileFavorites && new UsersProfileFavorites(response.ProfileFavorites);
    this.profilePhotoId = response.ProfilePhotoId;
    this.profilePhoto = getProfilePhotoUrl(response.Id, response.ProfilePhoto, 300);
    this.signUpDateTime = response.SignUpDateTime;
    this.PremiumEndDate = response.PremiumEndDate;
    this.spotCount = response.SpotCount;
    this.state = response.State;
    this.stats = response.Stats && new UserStats(response.Stats);
    this.waterwayCount = response.WaterwayCount;
    this.zip = response.Zip;
    this.waterways = (response.WaterWays || []).map(w => new UsersWaterway(w));
    this.biggestCatch = response.BiggestCatch && new UserBiggestCatch(response.BiggestCatch);
    this.favorites = response.Favorites && new UsersFavorites(response.Favorites);
  }
}

export class UserBiggestCatch {
  // TODO: replace with Catch model
  airTemperature: number; // TODO: check relevantion of that type
  catchDateTime: string;
  catchId: string;
  commentCount: number;
  commentedOnByUser: boolean;
  description: string;
  feedItemId: string;
  isBrag: boolean;
  lastUpdate: string;
  latestComment: string;
  latitude: number;
  length: number;
  likeCount: number;
  likedByUser: boolean;
  locationDescription: string; // TODO: check relevantion of that type
  longitude: number;
  lure: any; // TODO: use relevant type
  lureId: string;
  name: string;
  photoId: string;
  photo: string;
  postedDateTime: string;
  pressure: number; // TODO: check relevantion of that type
  privacyType: number;
  publicGPS: boolean;
  skyCondition: any; // TODO: use relevant type
  species: string;
  speciesId: string;
  waterDepth: string;
  waterTemperature: number; // TODO: check relevantion of that type
  waterwayId: string;
  waterwayName: string;
  weight: number;
  ounces: string;
  windDirection: string; // TODO: check relevantion of that type
  windSpeed: number; // TODO: check relevantion of that type

  constructor(response: any) {
    this.airTemperature = response.AirTemperature;
    this.catchDateTime = response.CatchDateTime;
    this.catchId = response.CatchId;
    this.commentCount = response.CommentCount;
    this.commentedOnByUser = response.CommentedOnByUser;
    this.description = response.Description;
    this.feedItemId = response.FeedItemId;
    this.isBrag = response.IsBrag;
    this.lastUpdate = response.LastUpdate;
    this.latestComment = response.LatestComment;
    this.latitude = response.Latitude;
    this.length = response.Length;
    this.likeCount = response.LikeCount;
    this.likedByUser = response.LikedByUser;
    this.locationDescription = response.LocationDescription;
    this.longitude = response.Longitude;
    this.lure = response.Lure;
    this.lureId = response.LureId;
    this.name = response.Name;
    this.photoId = response.PhotoId;
    this.photo = getPostPhotoUrl(response.PhotoId, 300);
    this.postedDateTime = response.PostedDateTime;
    this.pressure = response.Pressure;
    this.privacyType = response.PrivacyType;
    this.publicGPS = response.PublicGPS;
    this.skyCondition = response.SkyCondition;
    this.species = response.Species;
    this.speciesId = response.SpeciesId;
    this.waterDepth = response.WaterDepth;
    this.waterTemperature = response.WaterTemperature;
    this.waterwayId = response.WaterwayId;
    this.waterwayName = response.WaterwayName;
    this.weight = response.Weight;
    this.ounces = response.Weight && toPoundsAndOunces(response.Weight);
    this.windDirection = response.WindDirection;
    this.windSpeed = response.WindSpeed;
  }
}

export interface FeaturedUsers {
  AccountType: any;
  CatchCount: number;
  FriendCount: number;
  Friends: any;
  HasPendingRequest: boolean;
  Id: string;
  IsConnected: boolean;
  Location: string;
  Name: string;
  ProfilePhoto: string;
  SpotCount: number;
}

export class UserFollower {
  id: string;
  name: string;
  profilePhoto: string;
  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.profilePhoto = getProfilePhotoUrl(response.Id, response.ProfilePhoto);
  }
}
