import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './waterways.state';
import { request, success, failure, cleanState, collections, push, update } from '@app/utils/common';
import _ from 'lodash';
import { createMapUrl } from '@app/utils';

export default {
  getWaterway: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getWaterwayRequest: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getWaterway, payload, payload),
  getWaterwaySuccess: (state: StateInterface, { payload }: PayloadAction<any>) => collections(state.getWaterway, payload, payload.id),
  getWaterwayFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) => failure(state.getWaterway, error, key),

  getFollowedWaterways: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getFollowedWaterwaysSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    state.getFollowedWaterways = payload;
  },

  getWaterwayByLocation: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getWaterwayByLocationSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    state.getWaterwayByLocation = payload;
  },

  filterWaterways: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.filterWaterways, payload),
  filterWaterwaysSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => push(state.filterWaterways, payload),
  filterWaterwaysFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.filterWaterways, payload),

  getWaterwayDetails: (state: StateInterface, _action: PayloadAction<any>) => request(state.waterwayDetails),
  getWaterwayDetailsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.waterwayDetails, payload),
  getWaterwayDetailsFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.waterwayDetails, payload),

  getWaterwayDetailsById: (state: StateInterface, _action: PayloadAction<any>) => request(state.getWaterwayDetailsById),
  getWaterwayDetailsByIdSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getWaterwayDetailsById, payload),
  getWaterwayDetailsByIdFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getWaterwayDetailsById, payload),

  getNearbyWaterway: (state: StateInterface, _action: PayloadAction<any>) => {
    request(state.getNearbyWaterway);
    request(state.getFirstOfNearbyWaterway);
  },
  getNearbyWaterwaySuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    success(state.getNearbyWaterway, _.drop(payload));
    success(state.getFirstOfNearbyWaterway, payload[0]);
  },
  getNearbyWaterwayFailure: (state: StateInterface, { payload }: PayloadAction<any>) => {
    failure(state.getNearbyWaterway, payload);
    failure(state.getFirstOfNearbyWaterway, payload);
  },
  getFirstOfNearbyWaterway: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getFirstOfNearbyWaterway, payload),

  getFeed: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getFeed, payload),
  getFeedSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => push(state.getFeed, payload),
  getFeedFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getFeed, payload),

  getWaterwayPremium: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getWaterwayPremiumSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    state.getWaterwayPremium = payload;
  },

  searchWaterways: (_state: StateInterface, _action: PayloadAction<any>) => {},
  searchWaterwaysSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    state.searchWaterways = payload;
  },

  getWaterwayRegion: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getWaterwayRegionRequest: (state: StateInterface, { payload }: PayloadAction<any>) =>
    request(state.getWaterwayRegion, payload, createMapUrl(payload)),
  getWaterwayRegionSuccess: (state: StateInterface, { payload: { data, key } }: PayloadAction<any>) =>
    collections(state.getWaterwayRegion, data, key),
  getWaterwayRegionFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) =>
    failure(state.getWaterwayRegion, error, key),

  getWaterwayLanding: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getWaterwayLandingRequest: (state: StateInterface, { payload }: PayloadAction<any>) =>
    request(state.getWaterwayLanding, payload, createMapUrl(payload)),
  getWaterwayLandingSuccess: (state: StateInterface, { payload: { data, key } }: PayloadAction<any>) =>
    collections(state.getWaterwayLanding, data, key),
  getWaterwayLandingFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) =>
    failure(state.getWaterwayLanding, error, key),

  getRegions: (state: StateInterface) => request(state.getRegions),
  getRegionsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getRegions, payload),
  getRegionsFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getRegions, payload),

  userFeatured: (state: StateInterface) => request(state.getRegions),
  userFeaturedSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.userFeatured, payload),
  userFeaturedFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.userFeatured, payload),

  geocodingRegion: (state: StateInterface, _action: PayloadAction<any>) => request(state.getRegions),
  geocodingRegionSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.geocodingRegion, payload),
  geocodingRegionFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.geocodingRegion, payload),

  followWaterway: (state: StateInterface, { payload: { id, isConnected } }: PayloadAction<any>) => {
    update(state.getFirstOfNearbyWaterway, { isConnected: !isConnected }, { id });
    update(state.getWaterwayDetailsById, { IsConnected: !isConnected }, { WaterwayId: id });
  },
  followWaterwayFailure: (state: StateInterface, { payload: { id, isConnected } }: PayloadAction<any>) => {
    update(state.getFirstOfNearbyWaterway, { isConnected }, { id });
    update(state.getWaterwayDetailsById, { IsConnected: isConnected }, { WaterwayId: id });
  },

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
