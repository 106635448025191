import { relativePaths } from '@app/constants';
import { AuthService } from '@app/core/features/auth';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { Store } from '@ngrx/store';
import { fishingMapAction } from '@app/core/store/fishing-map/fishing-map.slice';

@Injectable()
export class MapAuthGuard implements CanActivate {
  constructor(private store: Store<{}>, private router: Router, private authService: AuthService, private route: ActivatedRoute) {}

  canActivate(): Observable<boolean> {
    return this.authService.getAuthFromStorage().pipe(map(this.handleAuthStateLoad.bind(this)));
  }

  handleAuthStateLoad = (auth): boolean => {
    if (this.authService.isAuthValid(auth)) {
      const paths = window.location.pathname.split('/');
      if (paths[1] === 'map') {
        const country = paths[2];
        const region = paths[3];
        const waterway = paths[4];
        this.store.dispatch(fishingMapAction.setNavigateData({ country, region, waterway }));
        this.router.navigate([relativePaths.FISHING_MAP]);
      }
    }
    return true;
  };
}
