import { ResponseApi, initResult } from '../../../utils/models';
export interface StateInterface {
  getLastWinner?: ResponseApi<any>;
  getWinners?: ResponseApi<any>;
  getContestEntries?: ResponseApi<any>;
}

export interface SelectorInterface {
  getLastWinner?: any;
  getWinners?: any;
  getContestEntries?: any;
}

export const initialState: StateInterface = {
  getLastWinner: initResult,
  getWinners: initResult,
  getContestEntries: initResult,
};
