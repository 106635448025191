export interface StateInterface {
  noAction?: any;
}

export interface SelectorInterface {
  noAction?: any;
}

export const initialState: StateInterface = {
  noAction: null,
};
