import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './spot.state';
import { request, success, failure, cleanState, collections } from '@app/utils/common';
import _ from 'lodash';
export default {
  createSpot: (_state: StateInterface, _action: PayloadAction<any>) => {},
  createSpotSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},

  editSpot: (_state: StateInterface, _action: PayloadAction<any>) => {},
  editSpotSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},
  editSpotFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  getMySpots: (state: StateInterface, _action: PayloadAction<any>) => request(state.getMySpots),
  getMySpotsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getMySpots, payload),
  getMySpotsFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getMySpots, payload),

  getSpot: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getSpotRequest: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getSpot, payload, payload),
  getSpotSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => collections(state.getSpot, payload, payload.id),
  getSpotFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) => failure(state.getSpot, error, key),


  deleteSpot: (_state: StateInterface, _action: PayloadAction<any>) => {},
  deleteSpotSuccess: (_state: StateInterface) => {},

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
