import { ResponseApi, initCollection, initResult } from '@app/utils';
export interface StateInterface {
  getMySpots?: ResponseApi<any>;
  getSpot?: ResponseApi<any>;
}

export interface SelectorInterface {
  getMySpots?: any;
  getSpot?: any;
}

export const initialState: StateInterface = {
  getMySpots: initResult,
  getSpot: initCollection,
};
