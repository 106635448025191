import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './conversation.state';
import { request, success, failure, cleanState, update, union, orderBy } from '@app/utils/common';
import _ from 'lodash';
import { ConversationPayload, ConversationsPayload } from '@app/core/features/conversations/conversations.payload';

export default {
  getConversationDetail: (state: StateInterface, _action: PayloadAction<ConversationPayload>) => request(state.getConversationDetail),
  getConversationDetailSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getConversationDetail, payload),
  getConversationDetailFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getConversationDetail, payload),

  getConversations: (state: StateInterface, { payload }: PayloadAction<ConversationsPayload>) => request(state.getConversations, payload),
  getConversationsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    union(state.getConversations, payload, 'id');
    orderBy(state.getConversations, (conver: any) => new Date(conver.lastActive), 'desc');
  },
  getConversationsFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getConversations, payload),

  markAllMessagesAsRead: (_state: StateInterface) => {},
  markAllMessagesAsReadSuccess: (state: StateInterface) => update(state.getConversations, { isRead: true }),

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
