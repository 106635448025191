export const WITHOUT_PHOTO_ID = '000000000000000000000000';

export const PROFILE_PLACEHOLDER_LIGHT = '/assets/images/profile-placeholder-light.svg';

export const PROFILE_PLACEHOLDER_DARK = '/assets/images/profile-placeholder-dark.svg';

export const SPOT_PLACEHOLDER_LIGHT = '/assets/images/spot-placeholder-light.svg';

export const SPOT_PLACEHOLDER_DARK = '/assets/images/spot-placeholder-dark.svg';

export const PREVIEW_IMAGE_DEFAULT = '/assets/images/add-photo.svg';
export const ONBOARDING_UPLOAD_IMAGE_DEFAULT = '../../assets/images/onboarding/default-upload-photo.png';
export const WATERWAY_DETAIL_BACKGROUND_PHOTO = '/assets/images/waterway-background.png';

export enum Icons {
  iconFacebook = 'facebook-icon',
  iconFacebookCircle = 'facebook-circle-icon',
  iconGoogle = 'google-icon',
  iconShow = 'show-icon',
  iconAppStore = 'app-store',
  iconPlayStore = 'playstore',
  iconNext = 'next-waterway-icon',
  iconUpperCase = 'upper-case-icon',
  iconSpecialCharacter = 'special-character-icon',
  iconSuccess = 'success-icon',
  iconClose = 'close-icon',
  iconEarth = 'earth-icon',
  iconTriangle = 'triangle-icon',
  iconPreviewImageDelete = 'preview-image-delete-icon',
  iconPreviewImageAdd = 'preview-image-plus-icon',
  iconFollowers = 'members-grey-icon',
  iconAddMsg = 'add-msg-icon',
  iconHotSpot = 'hotspot-icon',
  iconSentMsg = 'sent-msg-icon',
  iconVegetation = 'vegetation-icon',
  iconRealInput = 'real-input-icon',
  iconNounLotus = 'noun-lotus',
  iconCatch = 'catch-icon',
  iconSpot = 'spot-icon',
  iconPost = 'post-icon',
  iconPublic = 'public-icon',
  iconHidden = 'hidden-icon',
  iconPrivate = 'private-icon',
  iconOval = 'oval-2',
  iconWeather = 'group-3',
  iconPressure = 'pressure-icon',
  iconMoonrise = 'moonrise-icon',
  iconMoonSet = 'moonset-icon',
  iconWaterway = 'waterway-icon',
  iconMemberGrey = 'members-grey-icon',
  iconSpecies = 'species-icon',
  iconComment = 'comment-icon',
  iconViews = 'views-icon',
  iconLike = 'like-icon',
  iconListSelected = 'listselected-icon',
  iconSelected = 'selected-icon',
  iconThumbnail = 'thumbnail-icon',
  iconHighlightReelAdd = 'highlight-reel-add-icon',
  iconProfileAdd = 'profile-add-icon',
  iconPremiumContours = 'premium-contour',
  iconPremiumFishingHotspots = 'premium-fishing-hotspots',
  iconPremiumUnderwaterStructures = 'premium-underwater-structures',
  iconPremiumInsights = 'premium-insights',
  iconPdf = 'pdf',
  iconLocation = 'location-icon',
  iconMessenger = 'messenger',
  iconBaselineNotification = 'baseline-notification',
  iconWinnerBadge = 'winner-icon',
  iconPlusIconRound = 'plus-icon-round',
  iconBack = 'back-icon',
  iconLight = 'light-icon',
  searchNormal = 'search-normal',
  membersGreyIcon = 'members-grey-icon',
  subtractIcon = 'subtract-icon',
  notificationIcon = 'notification-icon',
  notFoundV2Icon = 'not-found-v2-icon',
  clipboardIcon = 'clipboard-icon',
  mapSearchIcon = 'map-search-icon',
  waterwayBlackIcon = 'waterway-black-icon',
  shapeIcon = 'shape-icon',
  notFoundIcon = 'not-found-icon',
  unionIcon = 'union-icon',
  zoomInIcon = 'zoom-in-icon',
  cloudIcon = 'cloud-icon',
  precipitationIcon = 'precipitation-icon',
  pressureIcon = 'pressure-icon',
  temperatureIcon = 'temperature-icon',
  windIcon = 'wind-icon',
  seaSurfaceTemperatureIcon = 'sea-surface-temperature-icon',
  catchOvIcon = 'catch-ov-icon',
  spotOvIcon = 'spot-ov-icon',
  followerIcon = 'follower-icon',
  mapIcon = 'map-icon',
  funIcon = 'fun-icon',
  communityIcon = 'community-icon',
  businessesIcon = 'businesses-icon',
  loggingIcon = 'logging-icon',
  mobileIcon = 'mobile-icon',
  doubleQuotes1Icon = 'double-quotes-icon-1',
  doubleQuotes2Icon = 'double-quotes-icon-2',
  lure18Icon = 'lure_18x18',
  skyCondition18Icon = 'sky-condition_18x18',
  wind18Icon = 'wind_18x18',
  airTemp18Icon = 'air-temp_18x18',
  airPressure18Icon = 'air-pressure_18x18',
}
