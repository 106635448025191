import { ObjectWithStringValues } from '@app/core/models';
import { getRelativePath } from '@app/utils';

const SIGN_IN = 'sign-in';
const ACTIVITY = 'activity';
const SIGN_UP = 'sign-up';
const SIGN_UP_FREE = 'sign-up-free';
const SIGN_UP_PREMIUM = 'premium';
const SIGN_UP_PAYMENT = 'payment';

const HOME = '';
const PAGE = 'page';
const HOME1 = 'home';
const FISHING_MAP = 'fishing-maps';
const MAP = 'map';
const MAP_COUNTRY = `${MAP}/:country`;
const MAP_COUNTRY_STATE = `${MAP_COUNTRY}/:state`;
const MAP_COUNTRY_STATE_REGION = `${MAP_COUNTRY_STATE}/:region`;
const MAP_COUNTRY_STATE_REGION_DUPE = `${MAP_COUNTRY_STATE_REGION}/:dupe`;
const PAGE_NOT_FOUND = 'page-not-found';
const TERMS = 'terms';
const PRIVACY = 'privacy';
const UNDER_CONSTRUCTION = 'under-construction';
const RESET_ACCOUNT = 'reset/:email/:hash';

const USER = 'user';
const PROFILE = 'profile';
const USER_PROFILE = `${USER}/${PROFILE}`;
const PROFILE_FOLLOWING = 'following';
const PROFILE_FOLLOWERS = 'followers';
const SEARCH_FOR_MEMBERS = 'search-for-members';
const MESSAGE = 'message';
const BRAGGIN_BOARD = 'braggin-board';
const RULES = 'rules';
const WALL = 'wall';
const NEW_MESSAGE = 'new';
const ACCOUNT = 'account';
const MY_CATCHES = 'my-catches';
const MY_SPOTS = 'my-spots';
const ONBOARDING = 'onboarding';
const FOLLOWED_WATERWAYS = 'followed-waterways';

const CONTACT = 'contact';
export const ID_PARAM_NAME = 'id';
const ID = `:${ID_PARAM_NAME}`;
const DETAIL_MESSAGE = `:${ID_PARAM_NAME}`;
const ABOUT = 'about';
const RAYMARINE_SYNC = 'raymarine-sync';
const FAQ = 'faq';
const SITEMAP = 'sitemap';
const FISHING_MOBILE_APP = 'fishing-mobile-app';
const THANK_YOU = 'Thank-You';
const PUBLIC_CATCH = ':id';
const PUBLIC_CATCH_PAGE = 'c';

const PUBLIC_SPOTS = 's';

export const paths: ObjectWithStringValues = {
  ID,
  HOME,
  HOME1,
  PAGE,
  PUBLIC_SPOTS,
  PUBLIC_CATCH,
  SIGN_IN,
  ACTIVITY,
  SIGN_UP,
  SIGN_UP_FREE,
  FISHING_MAP,
  MAP,
  MAP_COUNTRY,
  MAP_COUNTRY_STATE,
  MAP_COUNTRY_STATE_REGION,
  MAP_COUNTRY_STATE_REGION_DUPE,
  SIGN_UP_PREMIUM,
  PAGE_NOT_FOUND,
  TERMS,
  PRIVACY,
  SIGN_UP_PAYMENT,

  USER,
  PROFILE,
  USER_PROFILE,
  PROFILE_FOLLOWING,
  PROFILE_FOLLOWERS,
  MESSAGE,
  UNDER_CONSTRUCTION,
  BRAGGIN_BOARD,
  RULES,
  WALL,
  NEW_MESSAGE,
  DETAIL_MESSAGE,
  ACCOUNT,
  SEARCH_FOR_MEMBERS,
  MY_CATCHES,
  MY_SPOTS,
  ONBOARDING,
  FOLLOWED_WATERWAYS,
  ABOUT,
  RAYMARINE_SYNC,
  FAQ,
  SITEMAP,
  FISHING_MOBILE_APP,
  THANK_YOU,
  RESET_ACCOUNT,
  CONTACT,
  PUBLIC_CATCH_PAGE,
};

const getRelativePaths = () =>
  Object.entries(paths).reduce((result: ObjectWithStringValues, [key, value]): ObjectWithStringValues => {
    result[key] = getRelativePath(value);
    return result;
  }, {});

export const relativePaths: ObjectWithStringValues = getRelativePaths();
