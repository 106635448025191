import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './lure.state';
import { lureState } from './lure.slice';
import { request, success, failure, cleanState, set } from '@app/utils/common';
import _ from 'lodash';
export default {
  getLureList: (state: StateInterface) => request(state.getLureList),
  getLureListSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    success(state.getLureList, payload);
    set(state, lureState.groupLures, [{ id: 2, name: 'All Lure', items: payload }]);
  },
  getLureListFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getLureList, payload),

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
