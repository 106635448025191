import config from '@config/index';
import { paths, metadata } from './constants';
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

import { IconService } from '@app/core/services/icon/icon.service';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    router: Router,
    private titleService: Title,
    private metaService: Meta,
    private iconService: IconService,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.findMetaData(event.urlAfterRedirects);
        gtag('config', config.googleAnalyticsId, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  findMetaData(url: string) {
    const arrRouting = url.split('/');
    const routingName = arrRouting[arrRouting.length - 1];
    for (const propertyName in paths) {
      if (paths[propertyName] === routingName && metadata[propertyName] !== undefined) {
        this.bindMetaData(metadata[propertyName], 'title');
        this.bindMetaData(metadata[propertyName], 'description');
        this.bindMetaData(metadata[propertyName], 'keywords');
      }
    }
    this.metaService.updateTag({ name: 'author', content: config.author });
  }

  ngOnInit() {
    this.iconService.registerIcons();
  }

  bindMetaData(item, tagName) {
    if (item[tagName] !== undefined && item[tagName] !== '') {
      if (tagName === 'title') {
        this.titleService.setTitle(item['title']);
      }

      this.metaService.updateTag({ name: tagName, content: item[tagName] });
    } else {
      this.metaService.updateTag({ name: tagName });
    }
  }
}
