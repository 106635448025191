import { getProfilePhotoUrl, convertDateTimeConversation, convertMessage } from '@app/utils';
import { EntitiesCollection } from '@app/core/features/base';
import { User } from '@app/core/features/users/user-details.models';
import _ from 'lodash';

export class Conversation {
  id: string;
  initiatorUserId: string;
  subject: string;
  recipientUserIds: string[];
  messages: Message[];
  latestMessage: Message;
  lastActive: string;
  body: string;
  isRead: boolean;
  isMarketing: boolean;
  isMultiConversation: boolean;
  recipientUsersCollection: RecipientUsersCollection;
  recipientUsers: RecipientUsersResponse[];

  constructor(response: any) {
    this.id = response.ConversationId;
    this.initiatorUserId = response.InitiatorUserId;
    this.subject = response.Subject;
    this.recipientUserIds = response.RecipientUserIds;
    this.messages = response.Messages && response.Messages.map(mess => new Message(mess));
    this.latestMessage = this.messages && _.last(this.messages);
    this.lastActive = convertDateTimeConversation(response.LastActive);
    this.body = response.Body;
    this.isRead = response.IsRead;
    this.isMarketing = response.IsMarketing;
    this.isMultiConversation = response.RecipientUserIds && response.RecipientUserIds.length > 1;
    this.recipientUsersCollection =
      response.RecipientUsers &&
      response.RecipientUsers.reduce((collection, user) => {
        collection[user.Id] = new User(user);
        return collection;
      }, {});
    this.recipientUsers = response.RecipientUsers && response.RecipientUsers.map(user => new RecipientUsersResponse(user));
  }
}

export type RecipientUsersCollection = EntitiesCollection<User>;

export class Message {
  fromUserId: string;
  body: string;
  sentDateTime: string;
  isInitialMessage: boolean;
  catch: object; // TODO: add relevant type
  spot: object; // TODO: add relevant type

  constructor(response: any) {
    this.fromUserId = response.FromUserId;
    this.body = convertMessage(response.Body);
    this.sentDateTime = convertDateTimeConversation(response.SentDateTime);
    this.isInitialMessage = response.IsInitialMessage;
    this.catch = response.Catch;
    this.spot = response.Spot;
  }
}

export class RecipientUsersResponse {
  id: string;
  name: string;
  profilePhoto: string;

  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.profilePhoto = getProfilePhotoUrl(response.Id, response.ProfilePhoto);
  }
}

export interface FromUser {
  id: string;
  name: string;
  profilePhoto: string;
}

export interface UserSelected {
  id: string;
  name: string;
}

export interface ValueInputModel {
  message: string;
  subject: string;
}
