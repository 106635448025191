import { Post } from '@app/core/features/posts/post.models';
import { User } from '@app/core/features/users/user-details.models';
import { convertToCommonDateTimeWithSecond } from '@app/utils';

export class CatchPublic {

  catch: CatchDetail;

  post: Post;

  constructor(response: Record<string, any>) {
    if (!response) {
      this.catch = new CatchDetail(null);
      this.post = new Post(null);
      return;
    }

    if (response.Catch) {
      this.catch = new CatchDetail(response.Catch);
    }

    if (response.Post) {
      this.post = new Post(response.Post);
    }
  }

  static empty() {
    return new CatchPublic(null);
  }
}

export class CatchDetail {

  catchId: string;

  name: string;

  description: string;

  waterwayName: string;

  waterwayId: string;

  speciesId?: string;

  species?: string;

  catchDateTime: string;

  lure: string;

  lureId: string;

  weight?: number;

  length?: number;

  locationDescription: string;

  airTemperature?: number;

  waterDepth: string;

  wind?: string;

  pressure: string;

  skyCondition: string;

  waterTemperature: string;

  lastUpdated?: string;

  owner: User;

  latitude?: number;

  longitude?: number;

  photoId: string;

  likeCount: number;

  likedByUser: boolean;

  commentCount: number;

  feedItemId: string;

  latestComment: any;

  commentedOnByUser: boolean;

  postedDateTime: string;

  isBrag: boolean;

  publicGps: boolean;

  constructor(response: Record<string, any>) {
    if (!response) {
      return;
    }
    this.catchId = response.SpotId;
    this.name = response.Name;
    this.description = response.Description;
    this.waterwayName = response.WaterwayName;
    this.waterwayId = response.WaterwayId;
    this.catchDateTime = response.SpotDateTime;
    this.owner = new User(response.Owner);
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.photoId = response.PhotoId;
    this.likeCount = response.LikeCount;
    this.likedByUser = !!response.LikedByUser;
    this.commentCount = response.commentCount ?? 0;
    this.feedItemId = response.FeedItemId;
    this.latestComment = response.LatestComment;
    this.postedDateTime = convertToCommonDateTimeWithSecond(response.PostedDateTime as string);
    this.commentedOnByUser = !!response.CommentedOnByUser;
    this.lure = response.Lure;
    this.lureId = response.LureId;
    this.locationDescription = response.LocationDescription;
    this.airTemperature = response.AirTemperature;
    this.wind = response.Wind;
    this.waterDepth = response.WaterDepth;
    this.skyCondition = response.SkyCondition;
    this.waterTemperature = response.WaterTemperature;
    this.lastUpdated = convertToCommonDateTimeWithSecond(response.LastUpdated as string);
    this.isBrag = response.IsBrag;
    this.publicGps = response.PublicGps;
    this.species = response.Species;
    this.speciesId = response.SpeciesId;
  }

}

