import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { spotActions, spotSelector } from './spot.slice';
import { Store } from '@ngrx/store';
import { SpotService } from '@app/core/features/spot/spot.service';
import { commonActions } from '../common/common.slice';
import { of } from 'rxjs';
import { checkCollection } from '@app/utils';

@Injectable()
export class SpotEffects {
  createSpot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotActions.createSpot),
      mergeMap(action =>
        this.spotService.createSpot(action.payload).pipe(
          map((data: any) => {
            this.baseHelper.showSuccessActionToastr('Save the Spot successfully!');
            return spotActions.createSpotSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  editSpot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotActions.editSpot),
      mergeMap(action =>
        this.spotService.editSpot(action.payload).pipe(
          map((data: any) => {
            this.baseHelper.showSuccessActionToastr('Edit the Spot successfully!');
            return spotActions.editSpotSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, spotActions.editSpotFailure)),
        ),
      ),
    ),
  );

  getMySpots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotActions.getMySpots),
      mergeMap(action =>
        this.spotService.getList(action.payload).pipe(
          map((data: any) => spotActions.getMySpotsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, spotActions.getMySpotsFailure)),
        ),
      ),
    ),
  );
  getSpot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotActions.getSpot),
      withLatestFrom(this.store.select(spotSelector.getSpot)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, payload) && payload)),
      map(payload => (payload ? spotActions.getSpotRequest(payload) : commonActions.noAction())),
    ),
  );

  getSpotRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotActions.getSpotRequest),
      mergeMap(action =>
        this.spotService.getDetails(action.payload).pipe(
          map((data: any) => spotActions.getSpotSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, spotActions.getSpotFailure, action.payload)),
        ),
      ),
    ),
  );

  deleteCatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotActions.deleteSpot),
      mergeMap(action =>
        this.spotService.deleteSpot(action.payload).pipe(
          map(() => spotActions.deleteSpotSuccess()),
          catchError(() => of(commonActions.noAction())),
        ),
      ),
    ),
  );

  constructor(
    private spotService: SpotService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
