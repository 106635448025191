import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import spotReducer, { SPOT_KEY } from './spot.slice';
import { SpotEffects } from './spot.effects';

@NgModule({
  imports: [EffectsModule.forFeature([SpotEffects]), StoreModule.forFeature(SPOT_KEY, spotReducer)],
  providers: [],
})
export class SpotStoreModule {}
