import { ago, getProfilePhotoUrl, getPostPhotoUrl } from '@app/utils';
import { EntitiesCollection } from '@app/core/features/base';
import { RECIPIENT_WATER_TYPE, postTypes } from '@app/core/features/posts/posts.constants';

export class Post {
  id: string;
  type: string;
  typeId: string;
  datePosted: string;
  convertedDatePosted: string;
  message: string;
  authorId: string;
  authorName: string;
  authorProfilePhoto: string;
  authorType: number;
  privacy: number;
  recipientId: string;
  recipientName: string;
  recipientType: number;
  likeCount: number;
  commentCount: number;
  viewCount: number;
  photo: string;
  photoId: string;
  name: string;
  description: string;
  isOwner: boolean;
  canComment: boolean;
  likedByUser: boolean;
  comments: PostCommentsCollection;
  commentedOnByUser: boolean;
  hide: boolean;
  species: string;
  speciesId: string;
  isConnectedOwner: boolean;
  shouldShowWaterway: boolean;
  isCatch: boolean;
  isSpot: boolean;
  ownerHasPendingRequest: boolean;
  isPostEntity: boolean;
  waterwayIsoCountryCode: string;
  waterwayStateName: string;
  waterwayVanityUrlName: string;
  waterwayId: string;

  constructor(response: any) {
    if (!response) {
      return;
    }

    this.id = response.Id;
    this.type = response.Type;
    this.typeId = response.TypeId;
    this.datePosted = response.DatePosted;
    this.message = response.Message;
    this.authorId = response.AuthorId;
    this.authorName = response.AuthorName;
    this.authorProfilePhoto = getProfilePhotoUrl(response.AuthorId, response.AuthorProfilePhoto);
    this.authorType = response.AuthorType;
    this.privacy = response.Privacy;
    this.recipientId = response.RecipientId;
    this.recipientName = response.RecipientName;
    this.recipientType = response.RecipientType;
    this.shouldShowWaterway = response.RecipientType === RECIPIENT_WATER_TYPE;
    this.isCatch = response.Type === postTypes.CATCH_POST_TYPE;
    this.isSpot = response.Type === postTypes.SPOT_POST_TYPE;
    this.likeCount = response.LikeCount;
    this.commentCount = response.CommentCount;
    this.viewCount = response.ViewCount;
    this.photo = response.PhotoId && getPostPhotoUrl(response.PhotoId);
    this.photoId = response.PhotoId;
    this.name = response.Name;
    this.description = response.Description;
    this.isOwner = response.IsOwner;
    this.canComment = response.CanComment;
    this.likedByUser = response.LikedByUser;
    this.comments = response.Comments && response.Comments.reduce((result, comment) => {
      result[comment.Id] = new Comment(comment);
      return result;
    }, {});
    this.commentedOnByUser = response.CommentedOnByUser;
    this.hide = response.Hide;
    this.species = response.Species;
    this.speciesId = response.SpeciesId;
    this.convertedDatePosted = response.DatePosted && ago(response.DatePosted);
    this.isConnectedOwner = response.IsConnectedOwner;
    this.ownerHasPendingRequest = response.OwnerHasPendingRequest;
    this.isPostEntity = true;
    this.waterwayIsoCountryCode = response.WaterwayIsoCountryCode;
    this.waterwayStateName = response.WaterwayStateName;
    this.waterwayVanityUrlName = response.WaterwayVanityUrlName;
    this.waterwayId = response.WaterwayId;
  }
}

export type PostCommentsCollection = EntitiesCollection<Comment>;

export class Comment {
  id: string;
  authorName: string;
  authorId: string;
  authorProfilePhoto: string;
  authorType: number;
  datePosted: string;
  convertedDatePosted: string;
  message: string;
  parentId: string;
  isOwner: boolean;
  likedByUser: boolean;
  likeCount: number;
  isConnectedOwner: boolean;
  ownerHasPendingRequest: boolean;

  constructor(response: any) {
    this.id = response.Id;
    this.authorName = response.AuthorName;
    this.authorId = response.AuthorId;
    this.authorProfilePhoto = getProfilePhotoUrl(response.AuthorId, response.AuthorProfilePhoto);
    this.authorType = response.AuthorType;
    this.datePosted = response.DatePosted;
    this.convertedDatePosted = response.DatePosted && ago(response.DatePosted);
    this.message = response.Message;
    this.parentId = response.ParentId;
    this.isOwner = response.IsOwner;
    this.likedByUser = response.LikedByUser;
    this.likeCount = response.LikeCount;
    this.isConnectedOwner = response.IsConnectedOwner;
    this.ownerHasPendingRequest = response.OwnerHasPendingRequest;
  }
}
