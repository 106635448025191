import { relativePaths } from '@app/constants';
import { AuthService } from '@app/core/features/auth';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WithoutAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.getAuthFromStorage().pipe(
      map(this.handleAuthStateLoad.bind(this)),
    );
  }

  handleAuthStateLoad = (auth): boolean => {
    if (!this.authService.isAuthValid(auth)) {
      return true;
    }

    this.router.navigate([relativePaths.ACTIVITY], { replaceUrl: true });
    return false;
  };
}
