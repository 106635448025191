import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { endpoints } from './post.endpoints';
import { Post } from './post.models';
import { CreatePostPayload } from './post.payload';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private http: HttpService) {}

  getPost = (postId: string): Observable<object> => this.http.get({ url: `${endpoints.getPost}/${postId}` }).pipe(handleResponse(Post));

  createComment = (body: any): Observable<object> => this.http.post({ body, url: endpoints.createComment }).pipe(handleResponse());

  likeComment = (params: any): Observable<object> => this.http.post({ url: endpoints.likeComment, params }).pipe(handleResponse());

  unlikeComment = (params: any): Observable<object> => this.http.post({ url: endpoints.unlikeComment, params }).pipe(handleResponse());

  likePost = (postId: string): Observable<object> => this.http.post({ url: `${endpoints.likePost}/${postId}` }).pipe(handleResponse());

  unlikePost = (postId: string): Observable<object> => this.http.post({ url: `${endpoints.unlikePost}/${postId}` }).pipe(handleResponse());

  deleteComment = (postId: string, params: any): Observable<object> =>
    this.http.remove({ url: `${endpoints.deleteComment}/${postId}`, params }).pipe(handleResponse());

  deletePost = (params: any): Observable<object> =>
    this.http.remove({ url: `${endpoints.deletePost}/${params.postId}` }).pipe(handleResponse());

  report = (body: any): Observable<object> => this.http.post({ body, url: endpoints.report }).pipe(handleResponse());

  createPost = (body: CreatePostPayload): Observable<object> => this.http.post({ body, url: endpoints.createPost }).pipe(handleResponse());

  editPost = (body: CreatePostPayload): Observable<object> =>
    this.http.post({ body, url: `${endpoints.editPost}/${body.Id}` }).pipe(handleResponse());

  getFeedByExtent = params => this.http.get({ url: endpoints.streamByExtent, params }).pipe(handleResponse(Post));

  getFeed = (params: any): Observable<object> => this.http.get({ url: endpoints.stream, params }).pipe(handleResponse(Post));
}
