export const OBJECT_WITHOUT_ID = '000000000000000000000000';
export const DEFAULT_MAP_CENTER = [-95.80078125, 38.134556577054134];
export const PAGINATION_STEP = 10;
export const FETCH_CONVERSATIONS_INTERVAL = 1000 * 60;
export const FETCH_NOTIFICATIONS_COUNT_INTERVAL = 1000 * 60;
export const CHARGIFYPAY_URL = 'https://fishidy.chargifypay.com/subscribe';
export const CHARGIFYPAY_ANNUAL_URL = `${CHARGIFYPAY_URL}/b8c6b88a3f4d/annual`;
export const CHARGIFYPAY_MONTHLY_URL = `${CHARGIFYPAY_URL}/ca5c23047dcd/monthly`;
export const DEBOUNCE_TIME_300 = 300;
export const INPUT_USER = 'User cannot be null or empty';
export const INPUT_SUBJECT = 'Subject cannot be null or empty';
export const INPUT_MESSAGE = 'Message cannot be null or empty';

export const notificationsRequestTypes = {
  FOLLOW_REQUEST: 0,
  ACTIVITY: 1,
  COMMENTS: 2,
  LIKE: 4,
};

export const notificationsRequestTypesList: number[] = Object.values(notificationsRequestTypes);

export const notificationsTypes = {
  FOLLOW_REQUEST: 0,
  CONNECTED_ACTIVITY: 1,
  ACTIVITY: 2,
  MESSAGE: 3,
  LIKE: 4,
  RELATED_POST_COMMENT: 5,
  MY_POST_COMMENT: 6,
  MY_POST_LIKE: 7,
  MY_COMMENT_LIKE: 8,
};

const POST_TYPE = 'Post';
const CATCH_TYPE = 'Catch';
const SPOT_TYPE = 'Spot';

export const feedItemTypes = {
  CATCH_TYPE,
  SPOT_TYPE,
};

export const feedItemTypesByIds = {
  0: POST_TYPE,
  1: CATCH_TYPE,
  2: POST_TYPE,
  3: POST_TYPE,
  4: SPOT_TYPE,
};

export const CONFIRM_MODAL = {
  POST_CONFIRM_CLOSING_TITLE: 'Discard Post',
  POST_CONFIRM_CLOSING_CONTENT: 'Are you sure you want to leave?  Your post won’t be saved.',
  SPOT_CONFIRM_CLOSING_TITLE: 'Discard Spot',
  SPOT_CONFIRM_CLOSING_CONTENT: 'Are you sure you want to leave?  Your spot won’t be saved.',
  CATCH_CONFIRM_CLOSING_TITLE: 'Discard Catch',
  CATCH_CONFIRM_CLOSING_CONTENT: 'Are you sure you want to leave?  Your catch won’t be saved.',
};

export const BRAGGIN_BOARD_TAB = {
  SUBMISSIONS: 'SUBMISSIONS',
  WINNERS: 'WINNERS',
};

export const BRAGGIN_BOARD_SUBMISSIONS_ORDER_BY_NAME = {
  NominatedDate: 'NominatedDate',
  Votes: 'Votes',
  Views: 'Views',
};

export const BRAGGIN_BOARD_SUBMISSIONS_ORDER_BY = [
  {
    id: 1,
    name: BRAGGIN_BOARD_SUBMISSIONS_ORDER_BY_NAME.NominatedDate,
    label: 'Recently Added',
  },
  {
    id: 2,
    name: BRAGGIN_BOARD_SUBMISSIONS_ORDER_BY_NAME.Votes,
    label: 'Most votes',
  },
  {
    id: 3,
    name: BRAGGIN_BOARD_SUBMISSIONS_ORDER_BY_NAME.Views,
    label: 'Most views',
  },
];

export const SYMBOL_LIST = {
  CATCH: {
    id: 'catches-point',
    icon: 'assets/images/catch-icon@3x.png',
    image: 'catch',
  },
  SPOT: {
    id: 'spots-point',
    icon: 'assets/images/spot-icon@3x.png',
    image: 'spot',
  },
  WATERWAY: {
    id: 'waterways-point',
    icon: 'assets/images/waterway-icon@3x.png',
    image: 'waterway',
  },
  FISHING_HOTSPOT: {
    id: 'FLIR_UserDefinedFeaturesP_Marked_Fishing_Hotspots',
  },
};

export const HOTSPOT_MODAL = {
  HOTSPOT_TITLE: 'Fishing Hot Spot®',
};
