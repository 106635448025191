export const USStates: any[] = [
  {name: 'Alabama', state: 'Alabama', acronym: 'AL' },
  {name: 'Alaska', state: 'Alaska', acronym: 'AK' },
  {name: 'Arizona', state: 'Arizona', acronym: 'AZ' },
  {name: 'Arkansas', state: 'Arkansas', acronym: 'AR' },
  {name: 'California', state: 'California', acronym: 'CA' },
  {name: 'Colorado', state: 'Colorado', acronym: 'CO' },
  {name: 'Connecticut', state: 'Connecticut', acronym: 'CT' },
  {name: 'Delaware', state: 'Delaware', acronym: 'DE' },
  {name: 'District Of Columbia', state: 'District of Columbia', acronym: 'DC' },
  {name: 'Florida', state: 'Florida', acronym: 'FL' },
  {name: 'Georgia', state: 'Georgia', acronym: 'GA' },
  {name: 'Hawaii', state: 'Hawaii', acronym: 'HI' },
  {name: 'Idaho', state: 'Idaho', acronym: 'ID' },
  {name: 'Illinois', state: 'Illinois', acronym: 'IL' },
  {name: 'Indiana', state: 'Indiana', acronym: 'IN' },
  {name: 'Iowa', state: 'Iowa', acronym: 'IA' },
  {name: 'Kansas', state: 'Kansas', acronym: 'KS' },
  {name: 'Kentucky', state: 'Kentucky', acronym: 'KY' },
  {name: 'Louisiana', state: 'Louisiana', acronym: 'LA' },
  {name: 'Maine', state: 'Maine', acronym: 'ME' },
  {name: 'Maryland', state: 'Maryland', acronym: 'MD' },
  {name: 'Massachusetts', state: 'Massachusetts', acronym: 'MA' },
  {name: 'Michigan', state: 'Michigan', acronym: 'MI' },
  {name: 'Minnesota', state: 'Minnesota', acronym: 'MN' },
  {name: 'Mississippi', state: 'Mississippi', acronym: 'MS' },
  {name: 'Missouri', state: 'Missouri', acronym: 'MO' },
  {name: 'Montana', state: 'Montana', acronym: 'MT' },
  {name: 'Nebraska', state: 'Nebraska', acronym: 'NE' },
  {name: 'Nevada', state: 'Nevada', acronym: 'NV' },
  {name: 'New Hampshire', state: 'New Hampshire', acronym: 'NH' },
  {name: 'New Jersey', state: 'New Jersey', acronym: 'NJ' },
  {name: 'New Mexico', state: 'New Mexico', acronym: 'NM' },
  {name: 'New York', state: 'New York', acronym: 'NY' },
  {name: 'North Carolina', state: 'North Carolina', acronym: 'NC' },
  {name: 'North Dakota', state: 'North Dakota', acronym: 'ND' },
  {name: 'Ohio', state: 'Ohio', acronym: 'OH' },
  {name: 'Oklahoma', state: 'Oklahoma', acronym: 'OK' },
  {name: 'Oregon', state: 'Oregon', acronym: 'OR' },
  {name: 'Pennsylvania', state: 'Pennsylvania', acronym: 'PA' },
  {name: 'Rhode Island', state: 'Rhode Island', acronym: 'RI' },
  {name: 'South Carolina', state: 'South Carolina', acronym: 'SC' },
  {name: 'South Dakota', state: 'South Dakota', acronym: 'SD' },
  {name: 'Tennessee', state: 'Tennessee', acronym: 'TN' },
  {name: 'Texas', state: 'Texas', acronym: 'TX' },
  {name: 'Utah', state: 'Utah', acronym: 'UT' },
  {name: 'Vermont', state: 'Vermont', acronym: 'VT' },
  {name: 'Virginia', state: 'Virginia', acronym: 'VA' },
  {name: 'Washington', state: 'Washington', acronym: 'WA' },
  {name: 'West Virginia', state: 'West Virginia', acronym: 'WV' },
  {name: 'Wisconsin', state: 'Wisconsin', acronym: 'WI' },
  {name: 'Wyoming', state: 'Wyoming', acronym: 'WY' },
  {name: 'Other', state: 'Other', acronym: 'Other' },
];

export const IsoCountryCode = 'US';
