import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import conversationReducer, { CONVERSATION_KEY } from './conversation.slice';
import { ConversationEffects } from './conversation.effects';

@NgModule({
  imports: [EffectsModule.forFeature([ConversationEffects]), StoreModule.forFeature(CONVERSATION_KEY, conversationReducer)],
  providers: [],
})
export class ConversationStoreModule {}
