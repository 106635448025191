import { convertToCommonDateTime, createMapUrl, getPostPhotoUrl } from '@app/utils';

export class MapCatch {
  id: string;
  species: string;
  speciesId: string;
  name: string;
  catchDateTime: string;
  photo: string;
  photoId: string;
  latitude: number;
  longitude: number;

  constructor(reponse) {
    this.id = reponse.CatchId;
    this.species = reponse.Species;
    this.speciesId = reponse.SpeciesId;
    this.name = reponse.Name;
    this.catchDateTime = reponse.CatchDateTime && convertToCommonDateTime(reponse.CatchDateTime);
    this.photoId = reponse.PhotoId;
    this.photo = reponse.PhotoId && getPostPhotoUrl(reponse.PhotoId, 60);
    this.latitude = reponse.Latitude;
    this.longitude = reponse.Longitude;
  }
}

export class MapSpot {
  id: string;
  description: string;
  name: string;
  spotDateTime: string;
  photoId: string;
  photo: string;
  latitude: number;
  longitude: number;

  constructor(response: any) {
    this.id = response.SpotId;
    this.description = response.Description;
    this.name = response.Name;
    this.spotDateTime = response.SpotDateTime && convertToCommonDateTime(response.SpotDateTime);
    this.photoId = response.PhotoId;
    this.photo = response.PhotoId && getPostPhotoUrl(response.PhotoId, 60);
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
  }
}

export class MapWaterway {
  id: string;
  name: string;
  features: string;
  isConnected: boolean;
  latitude: number;
  longitude: number;
  county: string;
  state: string;
  stateAbbr: string;
  regionAbbr: string;
  memberCount: number;
  hotSpotCount: number;
  isPremium: boolean;
  waterType: number;
  waterwayUrl: string;

  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.features = response.Features;
    this.isConnected = response.IsConnected;
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.county = response.County;
    this.state = response.State;
    this.stateAbbr = response.StateAbbr;
    this.regionAbbr = response.RegionAbbr;
    this.memberCount = response.MemberCount;
    this.hotSpotCount = response.HotSpotCount;
    this.isPremium = response.IsPremium;
    this.waterType = response.WaterType;
    this.waterwayUrl = createMapUrl(response);
  }
}
