import { ResponseApi, initCollection, initResult } from '@app/utils';

export interface StateInterface {
  getCurrentUser?: ResponseApi<any>;
  getUserDetails?: ResponseApi<any>;
  getUserFollowers?: ResponseApi<any>;
  updateUserProfile?: ResponseApi<any>;
  getMembers?: ResponseApi<any>;
}

export interface SelectorInterface {
  getCurrentUser?: any;
  getUserDetails?: any;
  getUserFollowers?: any;
  updateUserProfile?: any;
  getMembers?: any;
}

export const initialState: StateInterface = {
  getCurrentUser: initResult,
  getUserDetails: initCollection,
  getUserFollowers: initResult,
  updateUserProfile: initResult,
  getMembers: initResult,
};
