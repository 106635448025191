export default {
  apiUrl: 'https://www.fishidy.com/Api/v3',
  photoUrl: 'https://s3.amazonaws.com/photos.fishidy.com',
  googleAnalyticsId: 'G-J30JLEB70G',
  appleStoreUrl:
    'https://itunes.apple.com/app/apple-store/id561498932?pt=1374229&ct=fishidy.com_footer_button&mt=8',
  // eslint-disable-next-line max-len
  googleStoreUrl: 'https://play.google.com/store/apps/details?id=com.fishidy&referrer=utm_source%3Dfishidy%26utm_medium%3Dwebsite%26utm_content%3Dfooter%26utm_campaign%3Dapp%2520store%2520button',
  numberOfSliderItem: 1,
  mapboxApi: 'https://api.mapbox.com/styles/v1/',
  geocodingApi: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  mapboxToken: 'pk.eyJ1IjoiZGxpdHZhayIsImEiOiJjazllbDJhOW8wM2ViM2ZtdXhpZWdteGdvIn0.S7e1jPFlaoMKalyPBRleqQ',
  mapboxStylePrefix: 'mapbox://styles/',
  mapboxStyleUrl: 'dlitvak/ck56scped0w141cmizda1vvea',
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  awsRegion: '',
  isTranslateEnable: false,
  author: 'Fishidy, Inc.',
  defaultMapZoom: 13,
  imageSizeLimit: 5242880,
};
