import reducers from './species.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './species.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const SPECIES_KEY = 'species-store';

const speciesSlice = createSlice({ name: SPECIES_KEY, initialState, reducers });
const speciesSelector: SelectorInterface = buildSelector(speciesSlice, initialState);
const speciesActions = speciesSlice.actions;
const speciesState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default speciesSlice.reducer;
export { SPECIES_KEY, speciesActions, speciesSelector, speciesState };
