import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

export interface BackEndResponse {
  Message: string;
  Success: boolean;
  Result?: any;
  Error?: any;
}

export type CommonServiceMethod = (...args: any[]) => Observable<any>;

export type ActionHandler = (action?: Action) => Observable<Action>;

export type SilentActionHandler = (action?: Action) => void;

export interface EntitiesCollection<T> {
  [id: string]: T;
}

export interface BackendPhotoResponse {
  Message?: string;
  Success: boolean;
  Id?: string;
  Error?: string;
  Title?: string;
}
