import { OBJECT_WITHOUT_ID } from '@app/constants/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import { ResponseApi } from './models';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export const isValueExists = (value: any): boolean => value !== undefined && value !== null && value !== '';

export const isValueWithoutId = (value: any): boolean => !value || value === OBJECT_WITHOUT_ID;

export const handleContent = (content: any) => {
  if (content) {
    const urlPattern = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-\_]+(\.[com,net,org,gov,info,edu,us]{2,4}){1,2}[\/\S]+$/;
    content.split(/[\n, ]/g).forEach(element => {
      if (urlPattern.test(element)) {
        content = content.replace(
          element,
          `<a href="${element}" target="_blank">${_.truncate(element, { length: 40, separator: /,? +/ })}</a>`,
        );
      }
    });
    content = content.replace(/\n/g, '<br>');
  }
  return content;
};

export const handleResponse = (T: any = null, resultKey: string = null) =>
  mergeMap((response: any) => {
    if (_.isNull(response) || _.isUndefined(response)) {
      return of(response);
    }
    if (_.isArray(response)) {
      return of(T ? response.map(r => new T(r)) : response);
    }
    if (response.Success === false) {
      const { Result, Message, Error } = response;
      const message = Result || Message || Error;
      throw message || 'Request faild!';
    }

    let result = response.Result || response.Results || response;
    if (resultKey) {
      result = response[resultKey];
    }
    if (!T || _.isNull(result) || _.isUndefined(result)) {
      return of(result);
    }
    result = _.isArray(result) ? result.map(r => new T(r)) : new T(result);
    return of(result);
  });

export const buildSelector = (slice: any, initialState: any) => {
  const selectFeature = createFeatureSelector<ReturnType<typeof slice.reducer>>(slice.name);
  const setSelector = (selector: any, stateKey: string) => {
    selector[stateKey] = createSelector(
      selectFeature,
      state => state[stateKey],
    );
    if (initialState[stateKey] && initialState[stateKey].collection) {
      selector[stateKey]['collection'] = (collectionKey: string) =>
        createSelector(
          selectFeature,
          state => state[stateKey]?.collection[collectionKey],
        );
    }
    return selector;
  };
  return _.keys(initialState).reduce(setSelector, {});
};

export const request = (state: ResponseApi<any>, payload: any = null, key: string = null) => {
  if (!key) {
    state.isLoading = true;
    state.payload = payload;
  } else if (state.collection[key]) {
    state.collection[key].isLoading = true;
    state.collection[key].payload = payload;
  } else {
    state.collection[key] = {
      isLoading: true,
      result: null,
      payload,
      error: null,
    };
  }
};

export const success = <T = any>(state: ResponseApi<any>, payload: T) => {
  state.isLoading = false;
  state.result = payload;
  state.error = null;
};

export const collection = (state: ResponseApi<any>, payload: any, key: string) => {
  state.isLoading = false;
  state.collection[key] = payload;
  state.error = null;
};

export const collections = (state: ResponseApi<any>, payload: any, key: string) => {
  if (state.collection[key]) {
    state.collection[key].isLoading = false;
    state.collection[key].result = payload;
    state.collection[key].error = null;
  } else {
    state.collection[key] = {
      isLoading: false,
      result: payload,
      payload: null,
      error: null,
    };
  }
};

export const failure = (state: ResponseApi<any>, payload: any, key: string = null) => {
  if (!key) {
    state.isLoading = false;
    state.error = payload;
  } else {
    state.collection[key].isLoading = false;
    state.collection[key].error = payload;
  }
};

export const push = (state: ResponseApi<any>, payload: any) => {
  state.isLoading = false;
  state.result = _.concat(state.result || [], payload);
  state.length = payload.length;
  state.error = null;
};

export const assign = (state: ResponseApi<any>, payload: any) => {
  state.isLoading = false;
  state.result = _.assign(_.clone(state.result) || {}, payload);
  state.error = null;
};

export const union = (state: ResponseApi<any>, payload: any, key: string) => {
  state.isLoading = false;
  state.result = _.unionBy(payload, state.result || [], key);
  state.error = null;
};

export const orderBy = (state: ResponseApi<any>, iterates: any, orders = null) => {
  state.isLoading = false;
  state.result = orders ? _.orderBy(state.result, iterates, orders) : _.orderBy(state.result, iterates);
  state.error = null;
};

const filterUpdate = (item: any, payload: any, filter: any) => {
  let check = true;
  if (filter) {
    if (_.isEmpty(filter)) {
      check = false;
    }
    _.keys(filter).forEach(key => {
      if (filter[key] !== item[key]) {
        check = false;
      }
    });
  }
  if (check) {
    if (_.isFunction(payload)) {
      payload(item);
    } else {
      _.assign(item, payload);
    }
  }
};

export const update = (state: ResponseApi<any>, payload: any, filter = null) => {
  if (state.result) {
    const newResult = _.cloneDeep(state.result);
    const _filter = _.pickBy(filter, elm => !_.isUndefined(elm) && !_.isNull(elm));

    if (_.isArray(newResult)) {
      newResult.forEach(item => {
        filterUpdate(item, payload, _filter);
      });
    } else {
      filterUpdate(newResult, payload, _filter);
    }
    state.result = newResult;
  } else if (state.collection) {
    const newCollection = _.cloneDeep(state.collection);
    if (newCollection[filter]) {
      if (_.isFunction(payload)) {
        payload(newCollection[filter].result);
      } else {
        _.assign(newCollection[filter].result, payload);
      }
      state.collection = newCollection;
    }
  }
};

export const remove = (state: ResponseApi<any>, filter: any) => {
  state.isLoading = false;
  state.result = _.filter(_.cloneDeep(state.result), post => {
    let check = false;
    _.keys(filter).forEach(key => {
      check = post[key] !== filter[key];
    });
    return check;
  });
  state.error = null;
};

export const set = (state: any, key: string, payload: any) => {
  state[key] = payload;
};

export const cleanState = (state: any, key: string, initialState: any) => {
  if (key) {
    state[key] = initialState[key];
  } else {
    Object.keys(state).forEach(k => {
      state[k] = initialState[k];
    });
  }
};

export const checkCollection = (collection: any, key: string) =>
  (!collection || !collection[key] || (!collection[key].result && !collection[key].isLoading)) && !isValueWithoutId(key);

export const readImage =  (files: FileList) => new Promise<string | ArrayBuffer>((res, rej) => {

  if (!files || files.length === 0) {
    rej('File not found');
  }

  const reader = new FileReader();
  reader.onload =  (event)=> {
    res(reader.result);
  };
  reader.onerror = (e) => {
    rej(reader.error);
  };
  reader.readAsDataURL(files[0]);
});

export const dataURItoBlob = (dataUri: string | Blob) => {

  if (typeof dataUri !== 'string') { return dataUri; }
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUri.split(',')[1]);

  // separate out the mime component
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
};

export const createFormDataFromBlob = (f: Blob, fieldName: string): FormData => {
  const form = new FormData();
  form.append(fieldName, f);
  return form;
};
