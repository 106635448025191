import { ObjectWithStringValues } from '@app/core/models';

export const endpoints: ObjectWithStringValues = {
  detail: '/waterway/detail',
  nearby: '/waterway/searchByCoordinates',
  follow: '/waterway/follow',
  unfollow: '/waterway/unfollow',
  location: '/Waterway/SearchByLocation',
  search: '/waterway/search',
  landing: '/waterway/landing',
  userFeatured: '/User/Featured',
  waterwayList: '/Waterway/List',
  premium: '/Waterway/Premium',
  region: '/Waterway/Region',
  regions: '/Waterway/Regions',
};
