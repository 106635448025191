import { User } from './auth.models';

export class SignInPayload {
  constructor(public emailAddress: string, public password: string) {}
}

export class SignUpPayload {
  constructor(
    public FirstName: string,
    public LastName: string,
    public Email: string,
    public Password: string,
    public CurrentZipCode: string,
    public SourceDevice?: string,
    public DeviceInfo?: string,
    public OptIn?: string,
  ) {}
}

export class LoadAuthPayload {
  constructor(public token: string | null, public user: User | null) {}
}
