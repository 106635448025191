import reducers from './catch.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './catch.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const CATCH_KEY = 'catch-store';

const catchSlice = createSlice({ name: CATCH_KEY, initialState, reducers });
const catchSelector: SelectorInterface = buildSelector(catchSlice, initialState);
const catchAction = catchSlice.actions;
const catchState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default catchSlice.reducer;
export { CATCH_KEY, catchAction, catchSelector, catchState };
