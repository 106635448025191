import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { speciesActions, speciesSelector } from './species.slice';
import { Store } from '@ngrx/store';
import { SpeciesService } from '@app/core/features/species/species.service';
import { catchAction } from '../catch/catch.slice';
import { commonActions } from '../common/common.slice';
import { of } from 'rxjs';
import { checkCollection } from '@app/utils';

@Injectable()
export class SpeciesEffects {
  getSpecies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speciesActions.getSpecies, catchAction.getCatchSuccess),
      withLatestFrom(this.store.select(speciesSelector.getSpecies)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, payload.speciesId) && payload)),
      map(payload => (payload ? speciesActions.getSpeciesRequest(payload) : commonActions.noAction())),
    ),
  );

  getSpeciesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speciesActions.getSpeciesRequest),
      mergeMap((action: any) =>
        this.speciesService.getSpeciesDetail(action.payload).pipe(
          map((data: any) => speciesActions.getSpeciesSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, speciesActions.getSpeciesFailure, action.payload.speciesId)),
        ),
      ),
    ),
  );

  getSpeciesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speciesActions.getSpeciesList),
      mergeMap(() =>
        this.speciesService.getSpeciesList().pipe(
          map((data: any) => speciesActions.getSpeciesListSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, speciesActions.getSpeciesListFailure)),
        ),
      ),
    ),
  );

  constructor(
    private speciesService: SpeciesService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
