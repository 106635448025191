import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { usersActions, usersSelector } from './users.slice';
import { Store } from '@ngrx/store';
import { UserDetailsService } from '@app/core/features/users/user-details.service';
import { UserDetailsPayload } from '@app/core/features/users/user-details.payload';
import { of } from 'rxjs';
import _ from 'lodash';
import { commonActions } from '../common/common.slice';
import { AccountService } from '@app/core/features/account/account.service';
import { authActions } from '../auth/auth.slice';
import { checkCollection } from '@app/utils';

@Injectable()
export class UsersEffects {

  getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUserDetails),
      withLatestFrom(this.store.select(usersSelector.getUserDetails)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, payload.userId) && payload)),
      map(payload => (payload ? usersActions.getUserDetailsRequest(payload) : commonActions.noAction())),
    ),
  );

  getUserDetailsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUserDetailsRequest),
      mergeMap(action =>
        this.userDetailsService.getUserById(action.payload).pipe(
          map((data: any) => usersActions.getUserDetailsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, usersActions.getUserDetailsFailure, action.payload.userId)),
        ),
      ),
    ),
  );

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getCurrentUser),
      mergeMap(action =>
        this.userDetailsService.getUserById(action.payload).pipe(
          map((data: any) => usersActions.getCurrentUserSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, usersActions.getCurrentUserFailure)),
        ),
      ),
    ),
  );

  getUserFollowers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUserFollowers),
      mergeMap(action =>
        this.userDetailsService.getUserFollowers(action.payload).pipe(
          map((data: any) => usersActions.getUserFollowersSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, usersActions.getUserFollowersFailure)),
        ),
      ),
    ),
  );

  followUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.followUser),
      mergeMap(action =>
        this.userDetailsService.followUser(action.payload).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(usersActions.followUserFailure(action.payload))),
        ),
      ),
    ),
  );

  unfollowUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.unfollowUser),
      mergeMap(action =>
        this.userDetailsService.unfollowUser(action.payload).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(usersActions.unfollowUserFailure(action.payload))),
        ),
      ),
    ),
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateUserProfile),
      mergeMap(action =>
        this.userDetailsService.updateUserProfile(action.payload).pipe(
          map(() => usersActions.updateUserProfileSuccess(action.payload)),
          catchError(error => this.baseHelper.catchError(error, usersActions.updateUserProfileFailure)),
        ),
      ),
    ),
  );

  getMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getMembers),
      mergeMap(action =>
        this.accountService.getMembers(action.payload).pipe(
          map((data: any) => usersActions.getMembersSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, usersActions.getMembersFailure)),
        ),
      ),
    ),
  );

  afterAuthSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signinSuccess, authActions.loadAuthSuccess, usersActions.updateUserProfileSuccess),
      mergeMap(({ payload }: any) => {
        const params = payload && new UserDetailsPayload(payload.id || payload.user.id);
        return of(payload ? usersActions.getCurrentUser(params) : commonActions.noAction());
      }),
    ),
  );

  needGetUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.followUserFailure, usersActions.unfollowUserFailure),
      mergeMap(action => of(usersActions.getUserDetailsRequest(action.payload.id))),
    ),
  );

  constructor(
    private userDetailsService: UserDetailsService,
    private accountService: AccountService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
