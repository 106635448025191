import { ResponseApi, initResult } from '../../../utils/models';
export interface StateInterface {
  getConversationDetail?: ResponseApi<any>;
  getConversations?: ResponseApi<any>;
  markAllMessagesAsRead?: ResponseApi<any>;
  getFirstOfNearbyWaterway?: ResponseApi<any>;
}

export interface SelectorInterface {
  getConversationDetail?: any;
  getConversations?: any;
  markAllMessagesAsRead?: any;
  getFirstOfNearbyWaterway?: any;
}

export const initialState: StateInterface = {
  getConversationDetail: initResult,
  getConversations: initResult,
  markAllMessagesAsRead: initResult,
  getFirstOfNearbyWaterway: initResult,
};
