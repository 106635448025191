import { Waterway } from '@app/core/features/waterways/waterway.models';
import { ResponseApi, initCollection, initResult } from '../../../utils/models';
export interface StateInterface {
  getWaterway?: ResponseApi<any>;
  getFollowedWaterways?: any[];
  getWaterwayByLocation?: Waterway;
  filterWaterways?: ResponseApi<any>;
  waterwayDetails?: ResponseApi<any>;
  getWaterwayDetailsById?: ResponseApi<any>;
  getNearbyWaterway?: ResponseApi<any>;
  getFirstOfNearbyWaterway?: ResponseApi<any>;
  getFeed?: ResponseApi<any>;
  getWaterwayPremium?: any[];
  searchWaterways?: Waterway[];
  getWaterwayRegion?: ResponseApi<any>;
  getWaterwayLanding?: ResponseApi<any>;
  getRegions?: ResponseApi<any>;
  userFeatured?: ResponseApi<any>;
  geocodingRegion?: ResponseApi<any>;
}

export interface SelectorInterface {
  getWaterway?: any;
  getFollowedWaterways?: any;
  getWaterwayByLocation?: any;
  filterWaterways?: any;
  waterwayDetails?: any;
  getWaterwayDetailsById?: any;
  getNearbyWaterway?: any;
  getFirstOfNearbyWaterway?: any;
  getFeed?: any;
  getWaterwayPremium?: any;
  searchWaterways?: any;
  getWaterwayRegion?: any;
  getWaterwayLanding?: any;
  getRegions?: any;
  userFeatured?: any;
  geocodingRegion?: any;
}

export const initialState: StateInterface = {
  getWaterway: initCollection,
  getFollowedWaterways: [],
  getWaterwayByLocation: null,
  waterwayDetails: initResult,
  filterWaterways: initResult,
  getWaterwayDetailsById: initResult,
  getNearbyWaterway: initResult,
  getFirstOfNearbyWaterway: initResult,
  getFeed: initResult,
  getWaterwayPremium: [],
  searchWaterways: [],
  getWaterwayRegion: initCollection,
  getWaterwayLanding: initCollection,
  getRegions: initResult,
  userFeatured: initResult,
  geocodingRegion: initResult,
};
