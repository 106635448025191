import { httpStatuses, relativePaths, UNAUTHORIZED_HEADER, endpoints } from '@app/constants';
import { AuthService } from '@app/core/features/auth';
import { LocationService } from '@app/core/services/location/location.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private locationService: LocationService,
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isUnauthorizedRequest = request.headers.has(UNAUTHORIZED_HEADER);

    if (isUnauthorizedRequest) {
      return this.handleUnauthorizedRequest(request, next);
    }

    return this.authService.getAuthFromStorage().pipe(
      mergeMap((auth) => this.handleRequest(request, next, auth)),
    );
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler, auth): Observable<HttpEvent<any>> {
    const { handleRequestResponse } = this;
    const unauthorizedRequest = request.headers.has(UNAUTHORIZED_HEADER);

    if ( unauthorizedRequest ||  !auth || !auth.token) {
      return handleRequestResponse(next.handle(request));
    }

    const authorizedRequest = request.clone({ setHeaders: { Token: auth.token } });

    return handleRequestResponse(next.handle(authorizedRequest));
  }

  private handleRequestResponse = (event: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> => event.pipe(
    tap(this.handleSuccessResponse.bind(this), this.handleFailedResponse.bind(this)),
  );

  private handleSuccessResponse = (response: HttpEvent<any>): void => { };

  private handleFailedResponse = (response: HttpEvent<any>): void => {
    if (!(response instanceof HttpErrorResponse)) {
      return;
    }

    if (response.status === httpStatuses.UNAUSTHORIZED) {
      this.handleUnauthorizedResponse();
    }
  };

  private handleUnauthorizedResponse = () => {
    this.authService.removeAuthFromStorage().subscribe(() => {
      this.locationService.replaceWithParam(relativePaths.SIGN_IN, this.router.url);
    });
  };

  private handleUnauthorizedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = request.headers.delete(UNAUTHORIZED_HEADER);
    const updatedRequest = request.clone({ headers });

    return next.handle(updatedRequest);
  }
}
