import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import usersReducer, { USERS_KEY } from './users.slice';
import { UsersEffects } from './users.effects';

@NgModule({
  imports: [EffectsModule.forFeature([UsersEffects]), StoreModule.forFeature(USERS_KEY, usersReducer)],
  providers: [],
})
export class UsersStoreModule {}
