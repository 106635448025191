export class UserDetailsPayload {
  constructor(public userId: string) {}
}
export class FollowUserPayload {
  constructor(public id: string, public postId?: string) {}
}

export class UpdateProfilePayload {
  constructor(
    public Id: string,
    public FirstName: string,
    public LastName: string,
    public Zipcode: string,
    public Specie: string,
    public Lure: string,
    public Waterway: string,
    public Bio: string,
    public ProfilePhoto: string,
    public LureStrings: string[],
    public WaterwayStrings: string[],
    public SpeciesStrings: string[],
    public LureIds: string[],
    public WaterwayIds: string[],
    public SpeciesIds: string[],
  ) {}
}
export class UserFollowersPayload {
  constructor(public userId: string, public results: number = 10, public skip: number = 0) {}
}
