import { ObjectWithStringValues } from '@app/core/models';

export interface ServicePayloadFormat {
  body: object;
  params: object;
  query: ObjectWithStringValues;
}

export interface PayloadForFetchActions<T extends ServicePayloadFormat> {
  toServicePayloadFormat(): T;
}

export interface DefaultRejectedPayload {
  error: any;
}
