import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './fishing-map.state';
import { success, cleanState } from '@app/utils/common';
import _ from 'lodash';
import { fishingMapState as _state } from './fishing-map.slice';
import { CatchMapPayload, SpotMapPayload, WaterwayMapPayload } from '@app/core/features/fishing-map/fishing-map.payload';

export default {
  getCatchMap: (_state: StateInterface, _action: PayloadAction<CatchMapPayload>) => {},
  getCatchMapSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getCatchMap, payload),

  getSpotMap: (_state: StateInterface, _action: PayloadAction<SpotMapPayload>) => {},
  getSpotMapSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getSpotMap, payload),

  getCatchPublicMap: (_state: StateInterface, _action: PayloadAction<CatchMapPayload>) => {},
  getCatchPublicMapSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getCatcPublichMap, payload),

  getSpotPublicMap: (_state: StateInterface, _action: PayloadAction<SpotMapPayload>) => {},
  getSpotPublicMapSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getSpotPublicMap, payload),

  getWaterwayMap: (_state: StateInterface, _action: PayloadAction<WaterwayMapPayload>) => {},
  getWaterwayMapSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getWaterwayMap, payload),

  getDefaultMapStyleInfo: (_state: StateInterface) => {},
  getDefaultMapStyleInfoSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    state.getDefaultMapStyleInfo = payload;
  },

  setNavigateData: (state: StateInterface, { payload }: PayloadAction<any>) => _.set(state, _state.navigateData, payload),
  setZoom: (state: StateInterface, { payload }: PayloadAction<any>) => _.set(state, _state.zoom, payload),

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
