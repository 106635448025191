import config from '@config/index';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

const script = document.createElement('script');
script.src = `https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsId}"></script>`;
document.head.appendChild(script);

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});
