import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { notificationsActions } from './notifications.slice';
import { Store } from '@ngrx/store';
import { forkJoin, interval } from 'rxjs';
import { commonActions } from '../common/common.slice';
import { usersSelector } from '../users/users.slice';
import { conversationActions } from '../conversations/conversation.slice';
import { NotificationsService } from '@app/core/features/notifications/notifications.service';
import { FETCH_NOTIFICATIONS_COUNT_INTERVAL, notificationsRequestTypesList } from '@app/constants';

@Injectable()
export class NotificationsEffects {
  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        notificationsActions.getNotifications,
        notificationsActions.acceptFollowNotificationFailure,
        notificationsActions.dismissFollowNotificationFailure,
      ),
      mergeMap(() =>
        forkJoin(notificationsRequestTypesList.map(type => this.notificationsService.getList({ type }))).pipe(
          map((data: any) => notificationsActions.getNotificationsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, notificationsActions.getNotificationsFailure)),
        ),
      ),
    ),
  );

  getNotificationCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        notificationsActions.getNotificationCount,
        notificationsActions.getNotifications,
        conversationActions.markAllMessagesAsReadSuccess,
      ),
      mergeMap(() =>
        this.notificationsService.getCount().pipe(
          map((data: any) => notificationsActions.getNotificationCountSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, notificationsActions.getNotificationCountFailure)),
        ),
      ),
    ),
  );

  acceptFollowNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.acceptFollowNotification),
      mergeMap(action =>
        this.notificationsService.acceptFollowRequest(action.payload).pipe(
          map(() => commonActions.noAction()),
          catchError(error => this.baseHelper.catchError(error, notificationsActions.acceptFollowNotificationFailure)),
        ),
      ),
    ),
  );

  dismissFollowNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.dismissFollowNotification),
      mergeMap(action =>
        this.notificationsService.dismissFollowRequest(action.payload).pipe(
          map(() => commonActions.noAction()),
          catchError(error => this.baseHelper.catchError(error, notificationsActions.dismissFollowNotificationFailure)),
        ),
      ),
    ),
  );

  getNotificationCountWithInterval$ = createEffect(() =>
    interval(FETCH_NOTIFICATIONS_COUNT_INTERVAL).pipe(
      map(() => (this.userId ? notificationsActions.getNotificationCount() : commonActions.noAction())),
    ),
  );

  userId;
  constructor(
    private notificationsService: NotificationsService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {
    this.store.select(usersSelector.getCurrentUser).subscribe((res: any) => {
      if (res.result) {
        this.userId = res.result.id;
      }
    });
  }
}
