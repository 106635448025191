import { EntitiesCollection } from '@app/core/features/base';
import { convertToCommonDateTime, getPostPhotoUrl, toPoundsAndOunces, toFeetAndInches } from '@app/utils';
import { User } from '@app/core/features/users/user-details.models';
import { Comment } from '@app/core/features/posts/post.models';
import { Species } from '../species/species.models';
export type MyCatchesCollection = EntitiesCollection<Catch>;
export type FirstCatchesCollection = EntitiesCollection<Catch>;

export class Catch {
  id: string;
  name: string;
  description: string;
  species: string;
  speciesId: string;
  catchDateTime: string;
  lure: string;
  lureId: string;
  weight: number;
  length: number;
  ounces: any;
  inches: any;
  waterwayId: string;
  waterwayName: string;
  photoId: string;
  photo: string;
  latitude: number;
  longitude: number;
  locationDescription: string;
  airTemperature: number;
  waterDepth: string;
  pressure: string;
  skyCondition: string;
  waterTemperature: number;
  windDirection: string;
  windSpeed: number;
  lastUpdate: string;
  privacyType: number;
  owner: User;
  likeCount: number;
  likedByUser: boolean;
  commentCount: number;
  commentedOnByUser: boolean;
  feedItemId: string;
  latestComment: Comment;
  postedDateTime: string;
  isBrag: boolean;
  publicGPS: boolean;
  isCatchEntity: boolean;
  speciesDetails: Species;
  isCatch: boolean;
  shouldShowWaterway: boolean;
  convertedDatePosted: string;

  constructor(response: any) {
    this.id = response.CatchId;
    this.name = response.Name;
    this.description = response.Description;
    this.species = response.Species;
    this.speciesId = response.SpeciesId;
    this.catchDateTime = response.CatchDateTime && convertToCommonDateTime(response.CatchDateTime);
    this.lure = response.Lure;
    this.lureId = response.LureId;
    this.weight = response.Weight;
    this.length = response.Length;
    this.ounces = response.Weight && toPoundsAndOunces(response.Weight);
    this.inches = response.Length && toFeetAndInches(response.Length);
    this.waterwayId = response.WaterwayId;
    this.waterwayName = response.WaterwayName;
    this.photoId = response.PhotoId;
    this.photo = response.PhotoId && getPostPhotoUrl(response.PhotoId, 300);
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.locationDescription = response.LocationDescription;
    this.airTemperature = response.AirTemperature;
    this.waterDepth = response.WaterDepth;
    this.pressure = response.Pressure;
    this.skyCondition = response.SkyCondition;
    this.waterTemperature = response.WaterTemperature;
    this.windDirection = response.WindDirection;
    this.windSpeed = response.WindSpeed;
    this.lastUpdate = response.LastUpdate && convertToCommonDateTime(response.LastUpdate);
    this.privacyType = response.PrivacyType;
    this.owner = response.Owner && new User(response.Owner);
    this.likeCount = response.LikeCount;
    this.likedByUser = response.LikedByUser;
    this.commentCount = response.CommentCount;
    this.commentedOnByUser = response.CommentedOnByUser;
    this.feedItemId = response.FeedItemId;
    this.latestComment = response.LatestComment && new Comment(response.LatestComment);
    this.postedDateTime = response.PostedDateTime && convertToCommonDateTime(response.PostedDateTime);
    this.isBrag = response.IsBrag;
    this.publicGPS = response.PublicGPS;
    this.isCatchEntity = true;
  }
}

export class AddCatch {
  success: boolean;
  id: string;
  braggingPromo: number;
  description: string;
  isBraggingBoardActive: boolean;
  braggingBoardText: string;
  moreInfoUrl: string;
  errors: any[];

  constructor(response: any) {
    this.success = response.Success;
    this.id = response.Id;
    this.description = response.Description;
    this.braggingPromo = response.BraggingPromo;
    this.isBraggingBoardActive = response.IsBraggingBoardActive;
    this.braggingBoardText = response.BraggingBoardText;
    this.moreInfoUrl = response.MoreInfoUrl;
    this.errors = response.Errors;
  }
}
