import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import notificationsReducer, { NOTIFY_KEY } from './notifications.slice';
import { NotificationsEffects } from './notifications.effects';

@NgModule({
  imports: [EffectsModule.forFeature([NotificationsEffects]), StoreModule.forFeature(NOTIFY_KEY, notificationsReducer)],
  providers: [],
})
export class NotificationsStoreModule {}
