import { createMapUrl } from '@app/utils';
import { FeaturedUsers } from '../users/user-details.models';
import { Post } from '../posts/post.models';
import _ from 'lodash';

export class NearbyWaterway {
  id: string;
  name: string;
  county: string;
  state: string;
  isoCountryCode: string;
  catchCount: number;
  spotCount: number;
  memberCount: number;
  isConnected: boolean;
  latitude: number;
  longitude: number;
  description: string;
  waterwayUrl: string;

  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.county = response.County;
    this.state = response.State;
    this.description = response.Description;
    this.isoCountryCode = response.IsoCountryCode;
    this.spotCount = response.SpotCount;
    this.catchCount = response.CatchCount;
    this.memberCount = response.MemberCount;
    this.isConnected = response.IsConnected;
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.waterwayUrl = createMapUrl(response);
  }
}

export class WaterwayFeatures {
  hasContours: boolean;
  hasHotSpots: boolean;
  hasStructure: boolean;
  hasAccessPoints: boolean;
  constructor(response: any) {
    this.hasContours = response.HasContours;
    this.hasHotSpots = response.HasHotSpots;
    this.hasStructure = response.HasStructure;
    this.hasAccessPoints = response.HasAccessPoints;
  }
}

export class WaterwayLanding {
  waterway: Waterway;
  stream: Post[];
  nearbyWaterways: NearbyWaterway;
  featuredUsers: FeaturedUsers[];
  constructor(response: any) {
    this.waterway = new Waterway(response.Waterway);
    this.stream = response.Stream && response.Stream.map(post => new Post(post));
    this.nearbyWaterways = response.NearbyWaterways && response.NearbyWaterways.map(waterway => new NearbyWaterway(waterway));
    this.featuredUsers = response.FeaturedUsers;
  }
}

export class Waterway {
  id: string;
  name: string;
  description: string;
  features: WaterwayFeatures | string;
  species: string[];
  speciesList: object[];
  tips: string[];
  isConnected: boolean;
  latitude: number;
  longitude: number;
  county: string;
  state: string;
  stateAbbr: string;
  regionAbbr: string;
  isoCountryCode: string;
  rank: number;
  memberCount: number;
  catchCount: number;
  spotCount: number;
  hasContours: boolean;
  hasStructures: boolean;
  hotSpotCount: number;
  isPremium: boolean;
  hasPremiumAccess: boolean;
  acres: number;
  depth: number;
  stocking: string;
  bottom: string;
  seasonalMoves: string;
  recentCatches: object[];
  cover: string;
  waterType: number;
  Tips: string[];
  vanityUrlName: string;
  waterwayUrl: string;

  constructor(response: any) {
    this.id = response.Id || response.WaterwayId;
    this.name = response.Name;
    this.description = response.Description;
    this.features = !response.Features || _.isString(response.Features) ? response.Features : new WaterwayFeatures(response.Features);
    this.species = response.Species;
    this.speciesList = response.SpeciesList;
    this.tips = response.Tips;
    this.isConnected = response.IsConnected;
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.county = response.County;
    this.state = response.State;
    this.memberCount = response.MemberCount;
    this.catchCount = response.CatchCount;
    this.spotCount = response.SpotCount;
    this.hasContours = response.HasContours;
    this.hasStructures = response.HasStructures;
    this.hotSpotCount = response.HotSpotCount;
    this.isPremium = response.IsPremium;
    this.hasPremiumAccess = response.HasPremiumAccess;
    this.acres = response.Acres;
    this.depth = response.Depth;
    this.stocking = response.Stocking;
    this.bottom = response.Bottom;
    this.seasonalMoves = response.SeasonalMoves;
    this.recentCatches = response.RecentCatches;
    this.cover = response.Cover;
    this.waterType = response.WaterType;
    this.stateAbbr = response.StateAbbr;
    this.regionAbbr = response.RegionAbbr;
    this.isoCountryCode = response.IsoCountryCode;
    this.rank = response.Rank;
    this.waterwayUrl = createMapUrl(response);
  }
}
export class Region {
  isoCountryCode: string;
  nameAbbr: string;
  name: string;
  waterwayCount: string;
  premiumWaterwayCount: string;

  constructor(response: any) {
    this.isoCountryCode = response.ISOCountryCode;
    this.nameAbbr = response.NameAbbr;
    this.name = response.Name;
    this.waterwayCount = response.WaterwayCount;
    this.premiumWaterwayCount = response.PremiumWaterwayCount;
  }
}

export class WaterwayRegion {
  stream: Post[];
  waterways: any;
  featuredUsers: FeaturedUsers[];

  constructor(response: any) {
    this.stream = response.Stream && response.Stream.map(post => new Post(post));
    this.waterways = response.Waterways && response.Waterways.map(waterway => new Waterway(waterway));
    this.featuredUsers = response.FeaturedUsers;
  }
}
