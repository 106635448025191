import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import authReducer, { AUTH_KEY } from './auth.slice';
import { AuthEffects } from './auth.effects';

@NgModule({
  imports: [EffectsModule.forRoot([AuthEffects]), StoreModule.forFeature(AUTH_KEY, authReducer)],
  providers: [],
})
export class AuthStoreModule {}
