import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { NearbyWaterway, Waterway, Region, WaterwayLanding, WaterwayRegion } from './waterway.models';
import { endpoints } from './waterway.endpoints';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import config from '@config/index';

@Injectable({
  providedIn: 'root',
})
export class WaterwayService {
  constructor(private http: HttpService) {}

  filterWaterways = (params: any): Observable<any> => this.http.get({ url: endpoints.search, params }).pipe(handleResponse(Waterway));

  getWaterway = (id: any): Observable<object> => this.http.get({ url: endpoints.detail, params: { id } }).pipe(handleResponse(Waterway));

  getWaterwayDetails = (params: any): Observable<object> => this.http.get({ url: endpoints.detail, params }).pipe(handleResponse());

  getWaterwayDetailsById = (params: any): Observable<object> => this.http.get({ url: endpoints.detail, params }).pipe(handleResponse());

  getNearbyWaterway = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.nearby, params }).pipe(handleResponse(NearbyWaterway));

  followWaterway = (id: string): Observable<object> => this.http.post({ body: { id }, url: endpoints.follow }).pipe(handleResponse());

  unfollowWaterway = (id: string): Observable<object> => this.http.remove({ url: `${endpoints.unfollow}/${id}` }).pipe(handleResponse());

  getWaterwayByLocation = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.location, params }).pipe(handleResponse(Waterway, 'Waterway'));

  getWaterwayLanding = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.landing, params }).pipe(handleResponse(WaterwayLanding));

  userFeatured = (): Observable<object> => this.http.get({ url: endpoints.userFeatured }).pipe(handleResponse());

  getList = (id: string): Observable<object> =>
    this.http.get({ url: endpoints.waterwayList, params: { id } }).pipe(handleResponse(Waterway));

  getWaterwayPremium = (params: any): Observable<Waterway[]> =>
    this.http.get({ url: endpoints.premium, params }).pipe(handleResponse(Waterway));

  getWaterwayRegion = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.region, params }).pipe(handleResponse(WaterwayRegion));

  getRegions = (): Observable<object> => this.http.get({ url: endpoints.regions }).pipe(handleResponse(Region));

  geocodingRegion = (region: string, params) => this.http.get({ baseUrl: config.geocodingApi, url: `${region}.json`, params });
}
