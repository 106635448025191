import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './auth.state';
import { request, success, failure, cleanState } from '@app/utils/common';
import _ from 'lodash';
export default {
  signin: (state: StateInterface, _action: PayloadAction<any>) => request(state.signin),
  signinSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.signin, payload),
  signinFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.signin, payload),

  signout: (_state: StateInterface) => {},

  signup: (state: StateInterface, _action: PayloadAction<any>) => request(state.signup),
  signupSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.signup, payload),
  signupFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.signup, payload),

  loadAuthSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
