import reducers from './posts.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './posts.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const POSTS_KEY = 'posts-store';

const postsSlice = createSlice({ name: POSTS_KEY, initialState, reducers });
const postsSelector: SelectorInterface = buildSelector(postsSlice, initialState);
const postsActions = postsSlice.actions;
const postsState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default postsSlice.reducer;
export { POSTS_KEY, postsActions, postsSelector, postsState };
