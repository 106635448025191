import reducers from './contest.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './contest.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const CONTEST_KEY = 'contest-store';

const contestSlice = createSlice({ name: CONTEST_KEY, initialState, reducers });
const contestSelector: SelectorInterface = buildSelector(contestSlice, initialState);
const contestActions = contestSlice.actions;
const contestState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default contestSlice.reducer;
export { CONTEST_KEY, contestActions, contestSelector, contestState };
