import { environment } from '@env/environment';

import development from './development';
import stage from './stage';
import production from './production';

// default to development
let config = development;

// switch to stage or prod based on hostname
switch (window.location.hostname) {
case 'fishidy.com':
case 'www.fishidy.com':
case 'prod-www.fishidy.com':
case 'new.fishidy.com':
  config = production;
  break;
case 'staging.fishidy.com':
case 'stage-www.fishidy.com':
  config = stage;
  break;
case 'snapper.fishidy.com':
case 'dev-www.fishidy.com':
case 'dev.fishidy.milemarker.io':
  config = development;
  break;
}

export default config;
