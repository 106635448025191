import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { fishingMapAction } from './fishing-map.slice';
import { FishingMapService } from '@app/core/features/fishing-map/fishing-map.service';

@Injectable()
export class FishingMapEffects {
  getCatchMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fishingMapAction.getCatchMap),
      mergeMap(action =>
        this.fishingMapService.getCatchMap(action.payload).pipe(
          map(data => fishingMapAction.getCatchMapSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getSpotMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fishingMapAction.getSpotMap),
      mergeMap(action =>
        this.fishingMapService.getSpotMap(action.payload).pipe(
          map(data => fishingMapAction.getSpotMapSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getCatchPublicMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fishingMapAction.getCatchPublicMap),
      mergeMap(action =>
        this.fishingMapService.getCatchPublicMap(action.payload).pipe(
          map(data => fishingMapAction.getCatchPublicMapSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getSpotPublicMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fishingMapAction.getSpotPublicMap),
      mergeMap(action =>
        this.fishingMapService.getSpotPublicMap(action.payload).pipe(
          map(data => fishingMapAction.getSpotPublicMapSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getWaterwayMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fishingMapAction.getWaterwayMap),
      mergeMap(action =>
        this.fishingMapService.getWaterwayMap(action.payload).pipe(
          map(data => fishingMapAction.getWaterwayMapSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getDefaultMapStyleInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fishingMapAction.getDefaultMapStyleInfo),
      mergeMap(() =>
        this.fishingMapService.getDefaultMapStyleInfo().pipe(
          map((data: any) => fishingMapAction.getDefaultMapStyleInfoSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  constructor(private fishingMapService: FishingMapService, private baseHelper: BaseHelperService, protected actions$: Actions) {}
}
