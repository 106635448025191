import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Geolocation } from '@ionic-native/geolocation/ngx';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'fishidy' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    OverlayModule,
    HttpClientModule,
    ClipboardModule,
    CoreModule,
    AppRoutingModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [Geolocation],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
