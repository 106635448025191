import { ObjectWithStringValues } from '@app/core/models';

export const endpoints: ObjectWithStringValues = {
  detail: '/catch/detail',
  add: '/catch/add',
  edit: '/catch/edit',
  brag: '/catch/brag',
  firstCatches: '/catch/getRecentFirstCatches',
  catchList: '/Catch/List',
  deleteCatch: '/catch/delete',
  publicCatch: '/Admin/Catch/PublicDetail',
};
