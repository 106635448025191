import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { User, UserFollower } from './user-details.models';
import { endpoints } from './user-details.endpoints';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserDetailsService {
  constructor(private http: HttpService) {}

  getUserById = (params: any): Observable<object> => this.http.get({ url: endpoints.details, params }).pipe(handleResponse(User, 'User'));

  followUser = (body: any): Observable<object> => this.http.post({ body, url: endpoints.follow }).pipe(handleResponse());

  unfollowUser = (params: any): Observable<object> =>
    this.http.remove({ url: `${endpoints.unfollow}/${params.id}` }).pipe(handleResponse());

  updateUserProfile = (body: any): Observable<object> => this.http.post({ body, url: `${endpoints.update}` }).pipe(handleResponse());

  getUserFollowers = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.getUserFollowers, params }).pipe(handleResponse(UserFollower, 'Users'));
}
