import reducers from './notifications.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './notifications.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';

const NOTIFY_KEY = 'notifications-store';

const notificationsSlice = createSlice({ name: NOTIFY_KEY, initialState, reducers });
const notificationsSelector: SelectorInterface = buildSelector(notificationsSlice, initialState);
const notificationsActions = notificationsSlice.actions;
const notificationsState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default notificationsSlice.reducer;
export { NOTIFY_KEY, notificationsActions, notificationsSelector, notificationsState };
