import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { AddCatch, Catch } from '@app/core/features/catch/catch.models';
import { endpoints } from './catch.endpoints';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CatchPublic } from '@app/core/features/catch/catch-public.model';

@Injectable({
  providedIn: 'root',
})
export class CatchService {
  constructor(private http: HttpService) {}

  getDetails = (id: string): Observable<object> =>
    this.http.get({ url: `${endpoints.detail}/${id}` }).pipe(handleResponse(Catch));

  createCatch = (body: any): Observable<object> =>
    this.http.post({ url: endpoints.add, body }).pipe(handleResponse(AddCatch));

  editCatch = (body: any): Observable<object> =>
    this.http.post({ url: `${endpoints.edit}/${body.id}`, body }).pipe(handleResponse(AddCatch));

  submitCatchBrag = (id: string): Observable<object> =>
    this.http.post({ url: endpoints.brag, body: { id } }).pipe(handleResponse());

  firstCatches = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.firstCatches, params }).pipe(handleResponse(Catch));

  getList = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.catchList, params }).pipe(handleResponse(Catch));

  deleteCatch = (id: string): Observable<object> =>
    this.http.remove({ url: `${endpoints.deleteCatch}/${id}` }).pipe(handleResponse());

  publicCatch(id: string): Observable<object> {
    return this.http.get({ url: endpoints.publicCatch, params: { id } }).pipe(handleResponse(CatchPublic));
  }
}
