import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { conversationActions } from './conversation.slice';
import { interval } from 'rxjs';
import { ConversationsService } from '@app/core/features/conversations/conversations.service';
import { Store } from '@ngrx/store';
import { ConversationPayload, ConversationsPayload } from '@app/core/features/conversations/conversations.payload';
import { commonActions } from '../common/common.slice';
import { FETCH_CONVERSATIONS_INTERVAL, paths } from '@app/constants';
import { usersSelector } from '../users/users.slice';

@Injectable()
export class ConversationEffects {
  getConversationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActions.getConversationDetail),
      mergeMap(action =>
        this.conversationsService.getData(action.payload).pipe(
          map(data => conversationActions.getConversationDetailSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, conversationActions.getConversationDetailFailure)),
        ),
      ),
    ),
  );

  getConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActions.getConversations),
      mergeMap(action =>
        this.conversationsService.getList(action.payload).pipe(
          map(data => conversationActions.getConversationsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, conversationActions.getConversationsFailure)),
        ),
      ),
    ),
  );

  markAllMessagesAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActions.markAllMessagesAsRead),
      mergeMap(() =>
        this.conversationsService.markAllAsRead().pipe(
          map(() => conversationActions.markAllMessagesAsReadSuccess()),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getConversationInterval$ = createEffect(() =>
    interval(FETCH_CONVERSATIONS_INTERVAL).pipe(
      map(() => {
        const conversationId = this.getConversationId();
        const payload = new ConversationPayload(conversationId, this.userId);
        return this.userId && conversationId ? conversationActions.getConversationDetail(payload) : commonActions.noAction();
      }),
    ),
  );

  getConversationsInterval$ = createEffect(() =>
    interval(FETCH_CONVERSATIONS_INTERVAL).pipe(
      map(() => (this.userId ? conversationActions.getConversations(new ConversationsPayload()) : commonActions.noAction())),
    ),
  );

  userId;
  constructor(
    private conversationsService: ConversationsService,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
    private store: Store<{}>,
  ) {
    this.store.select(usersSelector.getCurrentUser).subscribe((res: any) => {
      if (res.result) {
        this.userId = res.result.id;
      }
    });
  }

  getConversationId() {
    const { HOME, USER, MESSAGE, NEW_MESSAGE } = paths;
    const pathname = window.location.pathname;
    const pathNew = [HOME, USER, MESSAGE, NEW_MESSAGE].join('/');
    const path = [HOME, USER, MESSAGE].join('/');
    return pathname.includes(path) && !pathname.includes(pathNew) ? pathname.split('/').pop() : null;
  }
}
