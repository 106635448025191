import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { Notification } from '@app/core/features/notifications/notifications.models';
import { NotificationsCount } from './notifications.models';
import { endpoints } from './notifications.endpoints';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpService) {}

  getList = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.list, params }).pipe(handleResponse(Notification, 'Notifications'));

  getCount = (): Observable<object> => this.http.get({ url: endpoints.count }).pipe(handleResponse(NotificationsCount));

  markAllAsReadNotifications = (): Observable<object> => this.http.post({ url: endpoints.markAllAsRead }).pipe(handleResponse());

  acceptFollowRequest = (body: any): Observable<object> =>
    this.http.post({ body, url: endpoints.acceptFollowRequest }).pipe(handleResponse());

  dismissFollowRequest = (body: any): Observable<object> =>
    this.http.post({ body, url: endpoints.dismissFollowRequest }).pipe(handleResponse());
}
