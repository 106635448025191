import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './catch.state';
import { request, success, failure, cleanState, update, collections } from '@app/utils/common';
import _ from 'lodash';
export default {
  createCatch: (_state: StateInterface, _action: PayloadAction<any>) => {},
  createCatchSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},

  editCatch: (_state: StateInterface, _action: PayloadAction<any>) => {},
  editCatchSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},
  editCatchFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  submitCatchBrag: (_state: StateInterface, _action: PayloadAction<any>) => {},
  submitCatchBragSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},

  getCatch: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getCatchRequest: (state: StateInterface, { payload }: PayloadAction<any>) =>
    request(state.getCatch, payload, payload),
  getCatchSuccess: (state: StateInterface, { payload }: PayloadAction<any>) =>
    collections(state.getCatch, payload, payload.id),
  getCatchFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) =>
    failure(state.getCatch, error, key),

  getFirstCatch: (state: StateInterface, _action: PayloadAction<any>) =>
    request(state.getFirstCatch),
  getFirstCatchSuccess: (state: StateInterface, { payload }: PayloadAction<any>) =>
    success(state.getFirstCatch, payload),
  getFirstCatchFailure: (state: StateInterface, { payload }: PayloadAction<any>) =>
    failure(state.getFirstCatch, payload),
  updateFirstCatch: (state: StateInterface, { payload: { id } }: PayloadAction<any>) => {
    const handleUpdate = (elm: any) => {
      elm.likeCount = elm.likeCount + (elm.likedByUser ? -1 : 1);
      elm.likedByUser = !elm.likedByUser;
    };
    update(state.getFirstCatch, handleUpdate, { feedItemId: id });
  },

  getMyCatch: (state: StateInterface, _action: PayloadAction<any>) =>
    request(state.getMyCatch),
  getMyCatchSuccess: (state: StateInterface, { payload }: PayloadAction<any>) =>
    success(state.getMyCatch, payload),
  getMyCatchFailure: (state: StateInterface, { payload }: PayloadAction<any>) =>
    failure(state.getMyCatch, payload),

  deleteCatch: (_state: StateInterface, _action: PayloadAction<any>) => {},
  deleteSpotSuccess: (_state: StateInterface) => {},

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
