import config from '@config/index';
import {
  WITHOUT_PHOTO_ID,
  PROFILE_PLACEHOLDER_DARK,
} from '@app/constants/images';

type PhotoSize = 60 | 300 | 800;

export const getFullUrl = (url: string, path: string): string => `${url}${getRelativePath(path)}`;

export const getPhotoUrl = (photoId: string, size: PhotoSize = 300): string => `${getFullUrl(config.photoUrl, photoId)}_${size}`;

const isPhotoExists = (photoId: string): boolean => !photoId || photoId === WITHOUT_PHOTO_ID;

const isProfilePhotoExists = (id: string, photoId: string): boolean => !photoId || photoId === WITHOUT_PHOTO_ID || photoId === id;

export const getProfilePhotoUrl = (authorId: string, photoId: string, size: PhotoSize = 60): string => {
  if (isProfilePhotoExists(authorId, photoId)) {
    return PROFILE_PLACEHOLDER_DARK;
  }

  return getPhotoUrl(photoId, size);
};

export const getPostPhotoUrl = (photoId: string, size: PhotoSize = 800): string => {
  if (isPhotoExists(photoId)) {
    return null;
  }

  return getPhotoUrl(photoId, size);
};

// HACK: getRelativePath declared as function declaration to avoid AOT compiler issues
export const getRelativePath = (path: string): string => `/${path}`;
