import { environment } from '@env/environment';
import { EnsureModuleLoadedOnceGuard } from '@app/core/guards';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FishingMapStoreModule } from './fishing-map/fishing-map.store';
import { WaterwaysStoreModule } from './waterways/waterways.store';
import { CatchStoreModule } from './catch/catch.store';
import { ContestStoreModule } from './contest/contest.store';
import { ConversationStoreModule } from './conversations/conversation.store';
import { CommonStoreModule } from './common/common.store';
import { LureStoreModule } from './lure/lure.store';
import { NotificationsStoreModule } from './notifications/notifications.store';
import { SpotStoreModule } from './spot/spot.store';
import { PostsStoreModule } from './posts/posts.store';
import { SpeciesStoreModule } from './species/species.store';
import { UsersStoreModule } from './users/users.store';
import { AuthStoreModule } from './auth/auth.store';
import { AccountStoreModule } from './account/account.store';
import { MediaStoreModule } from '@app/core/store/media/media.store';

const storeDevToolsConfig = {
  logOnly: environment.production,
};

@NgModule({
  imports: [
    StoreDevtoolsModule.instrument(storeDevToolsConfig),
    StoreModule.forRoot([]),
    AuthStoreModule,
    FishingMapStoreModule,
    WaterwaysStoreModule,
    CatchStoreModule,
    ContestStoreModule,
    ConversationStoreModule,
    CommonStoreModule,
    LureStoreModule,
    NotificationsStoreModule,
    PostsStoreModule,
    SpotStoreModule,
    SpeciesStoreModule,
    UsersStoreModule,
    AccountStoreModule,
    MediaStoreModule,
  ],
  providers: [],
})
export class RootStoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() targetModule: RootStoreModule) {
    super(targetModule);
  }
}
