import { paths } from './constants';
import { AuthGuard, WithoutAuthGuard, MobileDeviceGuard , MapAuthGuard} from './core/guards';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: paths.CONTACT,
    redirectTo: paths.FAQ,
    pathMatch: 'full',
  },
  {
    path: paths.SIGN_IN,
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule),
    canActivate: [WithoutAuthGuard],
  },
  {
    path: paths.SIGN_UP,
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: paths.SIGN_UP_PREMIUM,
    loadChildren: () => import('./pages/sign-up-premium/sign-up-premium.module').then(m => m.SignUpPremiumModule),
  },
  {
    path: paths.FISHING_MOBILE_APP,
    loadChildren: () => import('./pages/fishing-mobile-app/fishing-mobile-app.module').then(m => m.FishingMobileAppModule),
  },
  {
    path: paths.HOME,
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: paths.HOME1,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [WithoutAuthGuard],
  },
  {
    path: paths.HOME,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'corrected',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, WithoutAuthGuard, MobileDeviceGuard, MapAuthGuard],
})
export class AppRoutingModule {}
