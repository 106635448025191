import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@app/core/services/http';
import { handleResponse } from '@app/utils';
import { Spot } from '@app/core/features/spot/spot.models';
import { endpoints } from './spot.endpoints';
import { MySpotsPayload } from './spot.payload';
import { SpotPublic } from './spot-public.model';

@Injectable({
  providedIn: 'root',
})
export class SpotService {
  constructor(private http: HttpService) {}

  createSpot = (body: any): Observable<object> =>
    this.http.post({ body, url: endpoints.createSpot }).pipe(handleResponse(Spot, 'Spot'));

  editSpot = (body: any): Observable<object> =>
    this.http.post({ body, url: `${endpoints.editSpot}/${body.id}` }).pipe(handleResponse(Spot, 'Spot'));

  getDetails = (id: string): Observable<object> =>
    this.http.get({ url: `${endpoints.details}/${id}` }).pipe(handleResponse(Spot));

  getList = (params: MySpotsPayload): Observable<object> =>
    this.http.get({ url: endpoints.spotList, params }).pipe(handleResponse(Spot));

  getPublicSpot = (id: string): Observable<object> =>
    this.http.get({ url: endpoints.publicSpot, params: { id } }).pipe(handleResponse(SpotPublic));

  deleteSpot = (id: string): Observable<object> =>
    this.http.remove({ url: `${endpoints.deleteSpot}/${id}` }).pipe(handleResponse());
}
