import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './posts.state';
import { request, success, failure, cleanState, push, update, collections, remove } from '@app/utils/common';
import _ from 'lodash';

export default {
  createPost: (state: StateInterface, _action: PayloadAction<any>) => request(state.createPost),
  createPostSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.createPost, payload),
  createPostFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.createPost, payload),

  editPost: (_state: StateInterface, _action: PayloadAction<any>) => {},
  editPostSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},
  editPostFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  getFeed: (state: StateInterface, _action: PayloadAction<any>) => request(state.getFeed),
  getFeedSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    push(state.getFeed, payload);
  },
  getFeedFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getFeed, payload),

  getFeedNoLoading: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getFeedNoLoadingSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getFeed, payload),

  getFeedByExtent: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getFeedByExtent, payload),
  getFeedByExtentSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    push(state.getFeedByExtent, payload);
    _.forEach(payload, post => collections(state.getPost, post, post.id));
  },
  getFeedByExtentFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getFeedByExtent, payload),

  getPost: (_state: StateInterface, _action: PayloadAction<any>) => {},
  getPostRequest: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getPost, payload, payload),
  getPostSuccess: (state: StateInterface, action: PayloadAction<any>) => handleGetPost(state, action),
  getPostFailure: (state: StateInterface, { payload: { error, key } }: PayloadAction<any>) => failure(state.getPost, error, key),

  likePost: (state: StateInterface, action: PayloadAction<any>) => handleLikePost(state, action),
  likePostFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  likeComment: (state: StateInterface, action: PayloadAction<any>) => handleLikeComment(state, action),
  likeCommentFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  createComment: (state: StateInterface, action: PayloadAction<any>) => handleCreateComment(state, action),
  createCommentSuccess: (_state: StateInterface, _action: PayloadAction<any>) => {},
  createCommentFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  deleteComment: (state: StateInterface, action: PayloadAction<any>) => handleDeleteComment(state, action),
  deleteCommentFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  deletePost: (state: StateInterface, { payload }: PayloadAction<any>) => remove(state.getFeed, { id: payload.postId }),
  deletePostSuccess: (_state: StateInterface) => {},
  deletePostFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  afterFollowUser: (state: StateInterface, { payload: { postId } }: PayloadAction<any>) =>
    update(state.getPost, { ownerHasPendingRequest: true }, postId),
  afterUnfollowUser: (state: StateInterface, { payload: { postId } }: PayloadAction<any>) =>
    update(state.getPost, { isConnectedOwner: false }, postId),
  reportPost: (_state: StateInterface, _action: PayloadAction<any>) => {},
  closePostDetailsModal: (_state: StateInterface) => {},

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};

/* ============================================ Handle ============================================ */
const handleLikePost = (state: StateInterface, { payload: { id } }: PayloadAction<any>) => {
  const handleUpdate = (elm: any) => {
    elm.likeCount = elm.likeCount + (elm.likedByUser ? -1 : 1);
    elm.likedByUser = !elm.likedByUser;
  };
  update(state.getPost, handleUpdate, id);
  update(state.getFeed, handleUpdate, { id });
  update(state.getFeedByExtent, handleUpdate, { id });
};

const handleLikeComment = (state: StateInterface, { payload: { postId, commentId } }: PayloadAction<any>) => {
  const handleUpdate = (elm: any) => {
    const comment = elm.comments[commentId];
    comment.likeCount = comment.likeCount + (comment.likedByUser ? -1 : 1);
    comment.likedByUser = !comment.likedByUser;
  };
  update(state.getPost, handleUpdate, postId);
  update(state.getFeed, handleUpdate, { id: postId });
  update(state.getFeedByExtent, handleUpdate, { id: postId });
};

const handleDeleteComment = (state: StateInterface, { payload: { postId, commentId } }: PayloadAction<any>) => {
  const handleUpdate = (elm: any) => {
    elm.commentCount = elm.commentCount - 1;
    delete elm.comments[commentId];
  };
  update(state.getPost, handleUpdate, postId);
  update(state.getFeed, handleUpdate, { id: postId });
  update(state.getFeedByExtent, handleUpdate, { id: postId });
};

const handleCreateComment = (state: StateInterface, { payload }: PayloadAction<any>) => {
  update(state.getPost, (elm: any) => (elm.comments[payload.id] = payload), payload.postId);
  update(state.getFeed, (elm: any) => (elm.comments[payload.id] = payload), { id: payload.postId });
  update(state.getFeedByExtent, (elm: any) => (elm.comments[payload.id] = payload), { id: payload.postId });
};

const handleGetPost = (state: StateInterface, { payload }: PayloadAction<any>) => {
  collections(state.getPost, payload, payload.id);
  update(state.getFeed, payload, { id: payload.id });
  update(state.getFeedByExtent, payload, { id: payload.id });
};
