import { relativePaths } from '@app/constants';
import _ from 'lodash';

const truncate = n => (n > 0 ? Math.floor(n) : Math.ceil(n));

export const convertLngLatToDMS = (dd, longOrLat, numberOfFix: number): any => {
  const hemisphere = /^[WE]|(?:lon)/i.test(longOrLat) ? (dd < 0 ? 'W' : 'E') : dd < 0 ? 'S' : 'N';

  const absDD = Math.abs(dd);
  const degrees = truncate(absDD);
  const minutes = truncate((absDD - degrees) * 60);
  const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(numberOfFix);

  const dmsArray = [degrees, minutes, seconds, hemisphere];
  return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}"${dmsArray[3]} `;
};

export const convertToWeight = (pounds, ounces) => {
  let weight = 0;
  if (pounds) {
    weight = Number(pounds) * 16;
  }

  if (ounces) {
    if (weight == null) {
      weight = Number(ounces);
    } else {
      weight += Number(ounces);
    }
  }

  return weight;
};

export const createMapUrl = (params: any) => {
  const { IsoCountryCode, country, State, state, stateName, stateAbbr, Name, name, VanityUrlName, vanityName, region } = params;
  const { waterwayIsoCountryCode, waterwayStateName, waterwayVanityUrlName } = params;
  const parseCountry = _.kebabCase(IsoCountryCode || waterwayIsoCountryCode || country || 'us');
  const parseState = _.kebabCase(_.toLower(stateName || waterwayStateName || State || state || stateAbbr));
  const parseName = _.kebabCase(_.toLower(VanityUrlName || waterwayVanityUrlName || vanityName || Name || region || name));
  const pathArr = [relativePaths.MAP, parseCountry, parseState, parseName];
  _.remove(pathArr, p => !p);
  return pathArr.join('/');
};

export const toPoundsAndOunces = strOunces => {
  let oz = Number(strOunces);
  if (isNaN(oz)) {
    return strOunces;
  }
  if (oz < 16) {
    return `${oz} oz`;
  }
  const lbs = parseInt(`${oz / 16}`, 10);
  oz = oz % 16;
  if (oz <= 0) {
    return `${lbs} lb`;
  }
  return `${lbs} lb ${oz} oz`;
};

export const toFeetAndInches = strInches => {
  let inches = parseInt(strInches, 10);
  if (isNaN(inches)) {
    return strInches;
  }
  if (inches < 60) {
    return `${inches} in`;
  }
  const feet = Math.round(inches / 12);
  inches = inches - feet * 12;
  if (inches <= 0) {
    return `${feet} ft`;
  }
  return `${feet} ft ${inches} in`;
};

export const formatBytes = (bytes, numberOfFix: number) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(numberOfFix))} ${sizes[i]}`;
};
