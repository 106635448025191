import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import accountReducer, { ACCOUNT_KEY } from './account.slice';
import { AccountEffects } from './account.effects';

@NgModule({
  imports: [EffectsModule.forFeature([AccountEffects]), StoreModule.forFeature(ACCOUNT_KEY, accountReducer)],
  providers: [],
})
export class AccountStoreModule {}
