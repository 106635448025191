import { initialState, MediaState } from '@app/core/store/media/media.state';
import { cleanState, failure, request, success } from '@app/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { BackendPhotoResponse } from '@app/core/features/base';

export default  {
  uploadPhoto: (state: MediaState, { payload }: PayloadAction<FormData>) => request(state.uploadPhoto, payload),
  uploadPhotoSuccess: (state: MediaState, { payload }: PayloadAction<BackendPhotoResponse>) => success(state.uploadPhoto, payload),
  uploadPhotoFailure: (state: MediaState, { payload }: PayloadAction<any>) => failure(state.uploadPhoto, payload),
  cleanState: (state: MediaState, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
