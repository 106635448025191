import { BackEndResponse } from './base.models';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonActions } from '@app/core/store/common/common.slice';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseHelperService {
  constructor(private toastr: ToastrService) {}

  showSuccessActionToastr = (message: string, title = '', timeOut = 10000): void => {
    this.toastr.success(message, title, {
      timeOut,
    });
  };

  showFailureActionToastr = (error: any, title = '', timeOut = 10000): void => {
    const message = error?.error?.message || error?.error?.Message || error?.message || error?.Message;
    this.toastr.error(message || error, title, {
      timeOut,
    });
  };

  verifyServiceResponse = (response: any): void => {
    if (response instanceof HttpErrorResponse) {
      const backEndError = response.error as BackEndResponse;
      const message = backEndError ? backEndError.Message : response.message;
      throw new Error(message);
    }

    if (response instanceof Error) {
      throw new Error(response.message);
    }
  };

  catchError = (error: string | any, action = null, key = null) => {
    console.log(error);
    this.showFailureActionToastr(error);
    return of(action ? action(key ? { key, error } : error) : commonActions.noAction(error));
  };
}
