import { ResponseApi, initCollection, initResult } from '../../../utils/models';
export interface StateInterface {
  getSpecies?: ResponseApi<any>;
  getSpeciesList?: ResponseApi<any>;
}

export interface SelectorInterface {
  getSpecies?: any;
  getSpeciesList?: any;
}

export const initialState: StateInterface = {
  getSpecies: initCollection,
  getSpeciesList: initResult,
};
