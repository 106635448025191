import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { endpoints } from './conversations.endpoints';
import { Conversation } from './conversations.models';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, catchError } from 'rxjs/operators';
import {
  ConversationPayload,
  ConversationsPayload,
  NewConversationServicePayload,
  ReplyConversationServicePayload,
} from './conversations.payload';
import { BaseHelperService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  constructor(private http: HttpService, private baseHelper: BaseHelperService) {}

  getList = (params: ConversationsPayload): Observable<object> =>
    this.http.get({ url: endpoints.list, params }).pipe(handleResponse(Conversation));

  markAllAsRead = (): Observable<object> => this.http.post({ url: endpoints.markAllAsRead });

  getData = (params: ConversationPayload): Observable<Conversation[]> =>
    this.http.get({ url: endpoints.readConversation, params }).pipe(handleResponse(Conversation));

  replyMessage(body: ReplyConversationServicePayload): Observable<object> {
    return this.http.post({ body, url: endpoints.replyConversation }).pipe(handleResponse(Conversation));
  }
  newConversation(body: NewConversationServicePayload): Observable<object> {
    return this.http.post({ url: endpoints.newConversation, body }).pipe(
      mergeMap(handleResponse(Conversation)),
      catchError(error => {
        this.baseHelper.showFailureActionToastr(error);
        return of(error);
      }),
    );
  }
}
