import config from '@config/index';
import { httpMethods } from '@app/constants';
import { BaseRequestConfig, RequestConfig } from './http.models';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';

const { apiUrl } = config;

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private headers: HttpHeaders;
  private params: HttpParams;

  constructor(private httpClient: HttpClient) {
    this.resetHeadersAndParams();
  }

  get({ url, baseUrl = apiUrl, params = {} }: RequestConfig): Observable<object> {
    this.setParams(params);
    return this.request({ url, baseUrl, method: httpMethods.GET });
  }

  post({ url, baseUrl = apiUrl, body = null, params = {} }: RequestConfig): Observable<object> {
    this.setParams(params);
    return this.request({ url, baseUrl, body, method: httpMethods.POST });
  }

  put({ url, baseUrl = apiUrl, body = null, params = {} }: RequestConfig): Observable<object> {
    this.setParams(params);
    return this.request({ url, baseUrl, body, method: httpMethods.PUT });
  }

  remove({ url, baseUrl = apiUrl, params = {} }: RequestConfig): Observable<object> {
    this.setParams(params);
    return this.request({ url, baseUrl, method: httpMethods.DELETE });
  }

  setHeader(key: string, value?: any): HttpService {
    this.headers = this.headers.append(key, value);
    return this;
  }

  removeAllHeaders(): HttpService {
    this.headers.keys().forEach(key => {
      this.headers = this.headers.delete(key);
    });

    return this;
  }

  setParam(key: string, value: string): HttpService {
    this.params = this.params.append(key, value);
    return this;
  }

  setParams(params: any): HttpService {
    Object.keys(params).forEach(key => {
      if (params[key] || params[key] === 0 || params[key] === '') {
        if (_.isArray(params[key])) {
          (params[key] as (string | number | boolean)[]).forEach((value: string | number | boolean) => {
            this.params = this.params.append(key, value);
          });
        } else {
          this.params = this.params.append(key, params[key]);
        }
      }
    });
    return this;
  }

  postWithFile({ url, baseUrl = apiUrl, body = null }): Observable<object> {
    this.setFormDataHeaders();
    return this.request({ url, baseUrl, body, method: httpMethods.POST });
  }

  private request({ url, baseUrl = apiUrl, body = null, method = httpMethods.GET }: BaseRequestConfig): Observable<object> {
    const options = {
      body,
      headers: this.headers,
      params: this.params,
    };
    const absoluteUrl = `${baseUrl}${url}`;
    const request = this.httpClient.request(method, absoluteUrl, options);
    this.resetHeadersAndParams();

    return request;
  }

  private resetHeadersAndParams(): void {
    this.headers = new HttpHeaders();
    this.params = new HttpParams();
    this.setDefaultHeaders();
  }

  private setDefaultHeaders(): void {
    this.setHeader('Content-Type', 'application/json');
  }

  private setFormDataHeaders(): void {
    this.headers = new HttpHeaders();
  }
}
