import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { MapCatch, MapSpot, MapWaterway } from './fishing-map.models';
import { endpoints } from './fishing-map.endpoints';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FishingMapService {
  constructor(private http: HttpService) {}

  getCatchMap = (params: any): Observable<MapCatch[]> => this.http.get({ url: endpoints.catchMap, params }).pipe(handleResponse(MapCatch));

  getCatchPublicMap = (params: any): Observable<MapCatch[]> =>
    this.http.get({ url: endpoints.catchPublicMap, params }).pipe(handleResponse(MapCatch));

  getSpotMap = (params: any): Observable<MapSpot[]> => this.http.get({ url: endpoints.spotMap, params }).pipe(handleResponse(MapSpot));

  getSpotPublicMap = (body: any): Observable<MapSpot[]> =>
    this.http.post({ url: endpoints.spotPublicMap, body }).pipe(handleResponse(MapSpot));

  getWaterwayMap = (params: any): Observable<MapWaterway> =>
    this.http.get({ url: endpoints.waterwayMap, params }).pipe(handleResponse(MapWaterway, 'Waterway'));

  getDefaultMapStyleInfo = (): Observable<object> => this.http.get({ url: `${endpoints.mapStyle}` });
}
