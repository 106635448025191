import { relativePaths } from '@app/constants';
import { AuthService } from '@app/core/features/auth';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationService } from '@app/core/services/location/location.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private locationService: LocationService,
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.getAuthFromStorage().pipe(
      map(this.handleAuthStateLoad.bind(this)),
    );
  }

  handleAuthStateLoad = (auth): boolean => {
    if (this.authService.isAuthValid(auth)) {
      return true;
    }

    this.locationService.replace(relativePaths.SIGN_IN);

    return false;
  };
}
