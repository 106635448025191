import reducers from './fishing-map.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './fishing-map.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const FISHING_MAP_KEY = 'fishing-map-store';

const fishingMapSlice = createSlice({ name: FISHING_MAP_KEY, initialState, reducers });
const fishingMapSelector: SelectorInterface = buildSelector(fishingMapSlice, initialState);
const fishingMapAction = fishingMapSlice.actions;
const fishingMapState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default fishingMapSlice.reducer;
export { FISHING_MAP_KEY, fishingMapAction, fishingMapSelector, fishingMapState };
