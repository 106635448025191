import { ResponseApi, initCollection, initResult } from '@app/utils';
export interface StateInterface {
  createPost?: ResponseApi<any>;
  getFeed?: ResponseApi<any>;
  getFeedByExtent?: ResponseApi<any>;
  getPost?: ResponseApi<any>;
}

export interface SelectorInterface {
  createPost?: any;
  getFeed?: any;
  getFeedByExtent?: any;
  getPost?: any;
}

export const initialState: StateInterface = {
  createPost: initResult,
  getFeed: initResult,
  getFeedByExtent: initResult,
  getPost: initCollection,
};
