import {
  COMMON_DATE_FORMAT,
  COMMON_DATE_TIME_FORMAT,
  COMMON_DATE_TIME_FORMAT_CONVERSATION,
  COMMON_DATE_TIME_FORMAT_WITH_SECOND,
} from '@app/constants/time';
import moment from 'moment';

export const convertToCommonDate = (date: string): string => moment(date).format(COMMON_DATE_FORMAT);

export const convertToCommonDateTime = (date: string): string => moment(date).format(COMMON_DATE_TIME_FORMAT);

export const convertDateTimeConversation = (date: string): string => moment(date).format(COMMON_DATE_TIME_FORMAT_CONVERSATION);

export const convertToCommonDateTimeWithSecond = (date: string) => moment(date).format(COMMON_DATE_TIME_FORMAT_WITH_SECOND);

export const isExpiredDate = (date: string) => new Date(date) < new Date();

export const convertMsToSeconds = (ms: number): number => ms / 1000;

export const subtractDaysFromDate = (date: string | Date, daysCount: number): Date => {
  const dateValue = date instanceof Date ? new Date(date.valueOf()) : new Date(date);

  dateValue.setDate(dateValue.getDate() - daysCount);

  return dateValue;
};

export const getMondayOfCurrentWeek = (): Date => {
  const date = new Date();
  const numberOfDay = date.getDay() || 7; // We should subtract 7 days if getDay returns 0(Sunday)
  const numberOfMonday = 1;
  const diffInDays = Math.abs(numberOfMonday - numberOfDay);

  return subtractDaysFromDate(date, diffInDays);
};

export const ago = (value: any): string => {
  const SECOND = 1;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;

  let date = value;
  if (typeof value === 'string') {
    date = new Date(value);
  }

  const diff = Date.now() - date.getTime();

  const delta = Math.abs(diff) / 1000;

  if (delta < 0) {
    return 'the future';
  }

  if (delta < MINUTE) {
    return 'just now';
  }

  if (delta < 2 * MINUTE) {
    return '1min ago';
  }

  if (delta < 60 * MINUTE) {
    return `${Math.round(Math.abs(delta) / MINUTE)} mins ago`;
  }

  if (delta < 120 * MINUTE) {
    return '1hr ago';
  }

  if (delta < 24 * HOUR) {
    return `${Math.round(Math.abs(delta) / HOUR)} hrs ago`;
  }

  if (delta < 48 * HOUR) {
    return '1d ago';
  }

  if (delta < 30 * DAY) {
    return `${Math.round(Math.abs(delta) / DAY)} d ago`;
  }

  if (delta < 12 * MONTH) {
    const months = Math.round(Math.abs(delta) / MONTH);
    return months <= 1 ? '1mo ago' : `${months} mos ago`;
  }

  return convertToCommonDate(date);

};

export const getWeekListBetweenTwoDates = (startDate, endDate = moment().format('YYYY-MM-DD')) => {
  const weekArray = [];
  let currentDate = moment(startDate, 'YYYY-MM-DD');
  const endDateTemp = moment(endDate, 'YYYY-MM-DD');

  while (currentDate <= endDateTemp) {
    const startOfWeek = moment(currentDate).startOf('isoWeek').format('MM/DD/YYYY');
    const endOfWeek   = moment(currentDate).endOf('isoWeek').format('MM/DD/YYYY');
    const item = {
      startDate: moment(currentDate).startOf('isoWeek').format('YYYY-MM-DD'),
      endDate: moment(currentDate).endOf('isoWeek').format('YYYY-MM-DD'),
      label: `${startOfWeek} - ${endOfWeek}`,
    };
    weekArray.push(item);
    currentDate = moment(currentDate).add(1, 'weeks');
  }
  return weekArray;
};
