import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { contestActions } from './contest.slice';
import { Store } from '@ngrx/store';
import { ContestService } from '@app/core/features/contest/contest.service';
import { of } from 'rxjs';

@Injectable()
export class ContestEffects {
  getLastWinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contestActions.getLastWinner),
      mergeMap(action =>
        this.contestService.getWinner(action.payload).pipe(
          map((data: any) => contestActions.getLastWinnerSuccess(data)),
          catchError(error => of(contestActions.getLastWinnerFailure(error))),
        ),
      ),
    ),
  );

  getWinners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contestActions.getWinners),
      mergeMap(action =>
        this.contestService.getWinners(action.payload).pipe(
          map((data: any) => contestActions.getWinnersSuccess(data)),
          catchError(error => of(contestActions.getWinnersFailure(error))),
        ),
      ),
    ),
  );

  getContestEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contestActions.getContestEntries),
      mergeMap(action =>
        this.contestService.getEntries(action.payload).pipe(
          map((data: any) => contestActions.getContestEntriesSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, contestActions.getContestEntriesFailure)),
        ),
      ),
    ),
  );

  voteEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contestActions.voteEntry),
      mergeMap(action =>
        this.contestService.voteEntry(action.payload).pipe(
          map(() => contestActions.voteEntrySuccess()),
          catchError(() => of(contestActions.voteEntryFailure(action.payload))),
        ),
      ),
    ),
  );

  constructor(
    private contestService: ContestService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
