import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { waterwaysActions, waterwaysSelector } from './waterways.slice';
import { of } from 'rxjs';
import { PostService } from '@app/core/features/posts/post.service';
import { WaterwayService } from '@app/core/features/waterways/waterway.service';
import _ from 'lodash';
import { commonActions } from '../common/common.slice';
import { checkCollection, createMapUrl } from '@app/utils';
import { Store } from '@ngrx/store';

@Injectable()
export class WaterwaysEffects {
  getFollowedWaterways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getFollowedWaterways),
      mergeMap(action =>
        this.waterwayService.getList(action.payload).pipe(
          map(data => waterwaysActions.getFollowedWaterwaysSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getWaterwayByLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayByLocation),
      mergeMap(action =>
        this.waterwayService.getWaterwayByLocation(action.payload).pipe(
          map(data => waterwaysActions.getWaterwayByLocationSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  filterWaterways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.filterWaterways),
      mergeMap(action =>
        this.waterwayService.filterWaterways(action.payload).pipe(
          map(data => waterwaysActions.filterWaterwaysSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getWaterwayDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayDetails),
      mergeMap(action =>
        this.waterwayService.getWaterwayDetails(action.payload).pipe(
          map(data => waterwaysActions.getWaterwayDetailsSuccess(data)),
          catchError(error => of(waterwaysActions.getWaterwayDetailsFailure(error))),
        ),
      ),
    ),
  );

  getWaterwayDetailsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayDetailsById),
      mergeMap(action =>
        this.waterwayService.getWaterwayDetailsById(action.payload).pipe(
          map(data => waterwaysActions.getWaterwayDetailsByIdSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.getWaterwayDetailsByIdFailure)),
        ),
      ),
    ),
  );

  getNearbyWaterway$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getNearbyWaterway),
      mergeMap(action =>
        this.waterwayService.getNearbyWaterway(action.payload).pipe(
          map(data => waterwaysActions.getNearbyWaterwaySuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.getNearbyWaterwayFailure)),
        ),
      ),
    ),
  );

  getWaterway$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterway),
      withLatestFrom(this.store.select(waterwaysSelector.getWaterway)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, payload) && payload)),
      map(payload => (payload ? waterwaysActions.getWaterwayRequest(payload) : commonActions.noAction())),
    ),
  );

  getWaterwayRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayRequest),
      mergeMap((action: any) =>
        this.waterwayService.getWaterway(action.payload).pipe(
          map((data: any) => waterwaysActions.getWaterwaySuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.getWaterwayFailure, action.payload)),
        ),
      ),
    ),
  );

  getFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getFeed),
      mergeMap(action =>
        this.postService.getFeed(action.payload).pipe(
          map(data => waterwaysActions.getFeedSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.getFeedFailure)),
        ),
      ),
    ),
  );
  getWaterwayPremium$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayPremium),
      mergeMap(action =>
        this.waterwayService.getWaterwayPremium(action.payload).pipe(
          map(data => waterwaysActions.getWaterwayPremiumSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  searchWaterways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.searchWaterways),
      mergeMap(action =>
        this.waterwayService.filterWaterways(action.payload).pipe(
          map(data => waterwaysActions.searchWaterwaysSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getWaterwayRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayRegion),
      withLatestFrom(this.store.select(waterwaysSelector.getWaterwayRegion)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, createMapUrl(payload)) && payload)),
      map(payload => (payload ? waterwaysActions.getWaterwayRegionRequest(payload) : commonActions.noAction())),
    ),
  );

  getWaterwayRegionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayRegionRequest),
      mergeMap(({ payload }) =>
        this.waterwayService.getWaterwayRegion(payload).pipe(
          map(data => waterwaysActions.getWaterwayRegionSuccess({ data, key: createMapUrl(payload) })),
          catchError(error => of(waterwaysActions.getWaterwayRegionFailure({ error, key: createMapUrl(payload) }))),
        ),
      ),
    ),
  );

  getWaterwayLanding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayLanding),
      withLatestFrom(this.store.select(waterwaysSelector.getWaterwayLanding)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, createMapUrl(payload)) && payload)),
      map(payload => (payload ? waterwaysActions.getWaterwayLandingRequest(payload) : commonActions.noAction())),
    ),
  );

  getWaterwayLandingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getWaterwayLandingRequest),
      mergeMap(({payload}) =>
        this.waterwayService.getWaterwayLanding(payload).pipe(
          map(data => waterwaysActions.getWaterwayLandingSuccess({ data, key: createMapUrl(payload) })),
          catchError(error => of(waterwaysActions.getWaterwayLandingFailure({ error, key: createMapUrl(payload) }))),
        ),
      ),
    ),
  );

  getRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.getRegions),
      mergeMap(() =>
        this.waterwayService.getRegions().pipe(
          map(data => waterwaysActions.getRegionsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.getRegionsSuccess)),
        ),
      ),
    ),
  );

  userFeatured$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.userFeatured),
      mergeMap(() =>
        this.waterwayService.userFeatured().pipe(
          map(data => waterwaysActions.userFeaturedSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.userFeaturedSuccess)),
        ),
      ),
    ),
  );

  geocodingRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.geocodingRegion),
      mergeMap(({ payload }) =>
        this.waterwayService.geocodingRegion(payload.region, _.omit(payload, ['region'])).pipe(
          map(data => waterwaysActions.geocodingRegionSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, waterwaysActions.geocodingRegionFailure)),
        ),
      ),
    ),
  );

  followWaterway$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waterwaysActions.followWaterway),
      mergeMap(({ payload }) =>
        this.handleFollow(payload).pipe(
          map(() => commonActions.noAction()),
          catchError(() => of(waterwaysActions.followWaterwayFailure(payload))),
        ),
      ),
    ),
  );

  constructor(
    private waterwayService: WaterwayService,
    private postService: PostService,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
    protected store: Store<{}>,
  ) {}

  handleFollow = ({ id, isConnected }) =>
    isConnected ? this.waterwayService.unfollowWaterway(id) : this.waterwayService.followWaterway(id);
}
