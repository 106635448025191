import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { LureDropdownItem } from './lure.models';
import { endpoints } from './lure.endpoints';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LureService {
  constructor(private http: HttpService) {}

  getLureDropdownList = (): Observable<object> => this.http.get({ url: endpoints.getLureList }).pipe(handleResponse(LureDropdownItem));
}
