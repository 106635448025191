import reducers from './conversation.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './conversation.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const CONVERSATION_KEY = 'conversation-store';

const conversationSlice = createSlice({ name: CONVERSATION_KEY, initialState, reducers });
const conversationSelector: SelectorInterface = buildSelector(conversationSlice, initialState);
const conversationActions = conversationSlice.actions;
const conversationState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default conversationSlice.reducer;
export { CONVERSATION_KEY, conversationActions, conversationSelector, conversationState};
