import reducers from './spot.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './spot.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const SPOT_KEY = 'spot-store';

const spotSlice = createSlice({ name: SPOT_KEY, initialState, reducers });
const spotSelector: SelectorInterface = buildSelector(spotSlice, initialState);
const spotActions = spotSlice.actions;
const spotState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default spotSlice.reducer;
export { SPOT_KEY, spotActions, spotSelector, spotState };
