
export class LureDropdownItem {
  id: string;
  value: string;

  constructor(response: any){
    this.id = response.Id;
    this.value = response.Name;
  }
}
