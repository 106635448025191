import { ResponseApi, initResult } from '../../../utils/models';
export interface StateInterface {
  signin?: ResponseApi<any>;
  signup?: ResponseApi<any>;
}

export interface SelectorInterface {
  signin?: any;
  signup?: any;
}

export const initialState: StateInterface = {
  signin: initResult,
  signup: initResult,
};
