import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import fishingMapReducer, { FISHING_MAP_KEY } from './fishing-map.slice';
import { FishingMapEffects } from './fishing-map.effects';

@NgModule({
  imports: [StoreModule.forFeature(FISHING_MAP_KEY, fishingMapReducer), EffectsModule.forFeature([FishingMapEffects])],
  providers: [],
})
export class FishingMapStoreModule {}
