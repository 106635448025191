import { handleResponse } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import { endpoints } from './contest.endpoints';
import { ContestEntry } from './contest.models';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContestService {
  constructor(private http: HttpService) {}

  getWinner = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.getWinner, params }).pipe(handleResponse(ContestEntry, 'Entry'));

  getWinners = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.getWinners, params }).pipe(handleResponse(ContestEntry, 'Entries'));

  getEntries = (params: any): Observable<object> =>
    this.http.get({ url: endpoints.getEntries, params }).pipe(handleResponse(ContestEntry, 'Entries'));

  voteEntry = (params: any): Observable<object> => this.http.post({ url: endpoints.voteEntry, params: { id: params.id } });
}
