import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { catchAction, catchSelector } from './catch.slice';
import { of } from 'rxjs';
import { CatchService } from '@app/core/features/catch/catch.service';
import { Store } from '@ngrx/store';
import { postsActions } from '../posts/posts.slice';
import { commonActions } from '../common/common.slice';
import { checkCollection } from '@app/utils';
import { omit } from 'lodash';

@Injectable()
export class CatchEffects {
  createCatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.createCatch),
      mergeMap(action =>
        this.catchSpotService.createCatch(action.payload).pipe(
          map((data: any) => {
            this.baseHelper.showSuccessActionToastr('Save the Catch successfully!');
            return catchAction.createCatchSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  editCatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.editCatch),
      mergeMap(({ payload }) =>
        this.catchSpotService.editCatch(payload).pipe(
          map((data: any) => {
            this.baseHelper.showSuccessActionToastr('Edit the Catch successfully!');
            return catchAction.editCatchSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, catchAction.editCatchFailure)),
        ),
      ),
    ),
  );

  submitCatchBrag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.submitCatchBrag),
      mergeMap(action =>
        this.catchSpotService.submitCatchBrag(action.payload).pipe(
          map((data: any) => catchAction.submitCatchBragSuccess(data)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  getCatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.getCatch),
      withLatestFrom(this.store.select(catchSelector.getCatch)),
      mergeMap(([{ payload }, { collection }]: any) => of(checkCollection(collection, payload) && payload)),
      map(payload => (payload ? catchAction.getCatchRequest(payload) : commonActions.noAction())),
    ),
  );

  getCatchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.getCatchRequest),
      mergeMap((action: any) =>
        this.catchSpotService.getDetails(action.payload).pipe(
          map((data: any) => catchAction.getCatchSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, catchAction.getCatchFailure, action.payload)),
        ),
      ),
    ),
  );

  getFirstCatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.getFirstCatch),
      mergeMap(action =>
        this.catchService.firstCatches(action.payload).pipe(
          map((data: any) => catchAction.getFirstCatchSuccess(data)),
          catchError(error => of(catchAction.getFirstCatchFailure(error))),
        ),
      ),
    ),
  );

  updateFirstCatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postsActions.likePost),
      mergeMap(action => of(catchAction.updateFirstCatch(action.payload))),
    ),
  );

  getMyCatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.getMyCatch),
      mergeMap(action =>
        this.catchService.getList(action.payload).pipe(
          map((data: any) => catchAction.getMyCatchSuccess(data)),
          catchError(error => of(catchAction.getMyCatchFailure(error))),
        ),
      ),
    ),
  );

  deleteCatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(catchAction.deleteCatch),
      mergeMap(action =>
        this.catchService.deleteCatch(action.payload).pipe(
          map(() => catchAction.deleteSpotSuccess()),
          catchError(() => of(commonActions.noAction())),
        ),
      ),
    ),
  );


  catchCollection: any;

  constructor(
    private catchService: CatchService,
    private catchSpotService: CatchService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
