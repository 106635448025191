import { ObjectWithStringValues } from '@app/core/models';

export const endpoints: ObjectWithStringValues = {
  getNotificationSettingsUrl: '/Account/GetNotificationSettings',
  updateNotificationSettingsUrl: '/Account/UpdateNotificationSettings',
  retrievePasswordUrl: '/Account/RetrievePassword',
  resetAccount: '/Account/Reset',
  userDelete: '/User/Delete',
  getMembers: '/User/Search',
};
