import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { Actions, ofType, createEffect, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { authActions } from './auth.slice';
import { Store } from '@ngrx/store';
import { AuthService, REDIRECT_TO } from '@app/core/features/auth';
import { LocationService } from '@app/core/services/location/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CHARGIFYPAY_ANNUAL_URL, CHARGIFYPAY_MONTHLY_URL, paths, relativePaths } from '@app/constants';

@Injectable()
export class AuthEffects {
  redirectTo = REDIRECT_TO;

  signin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signin),
      mergeMap(action =>
        this.authService.signIn(action.payload).pipe(
          map((data: any) => authActions.signinSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, authActions.signinFailure)),
        ),
      ),
    ),
  );

  signInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.signinSuccess),
        tap(action => {
          this.authService.setAuthToStorage(action.payload);
          this.router.navigate([this.queryParams[this.redirectTo] || relativePaths.ACTIVITY], { replaceUrl: true });
        }),
      ),
    { dispatch: false },
  );

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.signout),
        tap(() => {
          this.authService.removeAuthFromStorage().subscribe(() => {
            this.locationService.replace(relativePaths.SIGN_IN);
          });
        }),
      ),
    { dispatch: false },
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signup),
      mergeMap(action =>
        this.authService.signUp(action.payload.body).pipe(
          map(response => authActions.signupSuccess({ payload: action.payload, response })),
          catchError(error => this.baseHelper.catchError(error, authActions.signinFailure)),
        ),
      ),
    ),
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.signupSuccess),
        tap(action => {
          const { payload, response } = action.payload;
          this.authService.setAuthToStorage(response);
          if (payload.isSignUpPremium) {
            const params = `?email=${payload.body.Email}&first_name=${payload.body.FirstName}&last_name=${payload.body.LastName}`;
            this.locationService.replace((payload.plan === 'annual' ? CHARGIFYPAY_ANNUAL_URL : CHARGIFYPAY_MONTHLY_URL) + params);
          } else {
            this.locationService.replace(`${paths.USER}/${paths.ONBOARDING}`);
          }
        }),
      ),
    { dispatch: false },
  );

  loadAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() =>
        this.authService.getAuthFromStorage().pipe(
          map(auth => authActions.loadAuthSuccess(auth)),
          catchError(error => this.baseHelper.catchError(error)),
        ),
      ),
    ),
  );

  queryParams: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private locationService: LocationService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });
  }
}
