import { ResponseApi, initResult, initCollection } from '@app/utils';
export interface StateInterface {
  getCatch?: ResponseApi<any>;
  getFirstCatch?: ResponseApi<any>;
  getMyCatch?: ResponseApi<any>;
}

export interface SelectorInterface {
  getCatch?: any;
  getFirstCatch?: any;
  getMyCatch?: any;
}

export const initialState: StateInterface = {
  getCatch: initCollection,
  getFirstCatch: initResult,
  getMyCatch: initResult,
};
