import { ResponseApi, initResult } from '../../../utils/models';
export interface StateInterface {
  getLureList?: ResponseApi<any>;
  groupLures?: any;
}

export interface SelectorInterface {
  getLureList?: any;
  groupLures?: any;
}

export const initialState: StateInterface = {
  getLureList: initResult,
  groupLures: [],
};
