export class ConversationsPayload {
  constructor(public skip = 0, public results = 10) {}
}
export class ConversationPayload {
  constructor(public conversationId: string, public id: string) {}
}

export interface ReplyConversationServicePayload {
  conversationId: string;
  body: string;
  catchId: string;
  spotId: string;
}

export interface NewConversationServicePayload {
  subject: string;
  recipientUserIds: string[];
  body: string;
  catchId: string;
  spotId: string;
}
