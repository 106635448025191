import reducers from './common.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './common.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const COMMON_KEY = 'common-store';

const commonSlice = createSlice({ name: COMMON_KEY, initialState, reducers });
const commonSelector: SelectorInterface = buildSelector(commonSlice, initialState);
const commonActions = commonSlice.actions;
const commonState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default commonSlice.reducer;
export { COMMON_KEY, commonActions, commonSelector, commonState };
