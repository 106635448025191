import { ObjectWithStringValues } from '@app/core/models';

export const endpoints: ObjectWithStringValues = {
  createSpot: '/Spot/Add',
  editSpot: '/Spot/edit',
  details: '/spot/detail',
  spotList: '/Spot/List',
  publicSpot: '/Admin/Spot/PublicDetail',
  deleteSpot: '/spot/delete',
};
