import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './notifications.state';
import { request, success, failure, cleanState, orderBy } from '@app/utils/common';
import _ from 'lodash';
export default {
  getNotifications: (state: StateInterface) => request(state.getNotifications),
  getNotificationsSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    success(state.getNotifications, _.flattenDeep(payload));
    orderBy(state.getNotifications, ['isNew', (noti: any) => new Date(noti.lastActive)], ['desc', 'desc']);
  },
  getNotificationsFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getNotifications, payload),

  getNotificationCount: (state: StateInterface) => request(state.getNotificationCount),
  getNotificationCountSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => {
    success(state.getNotificationCount, _.sum(_.values(_.omit(payload, ['messagesCount']))));
    success(state.getMessageCount, _.values(_.pick(payload, ['messagesCount'])));
  },
  getNotificationCountFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getNotificationCount, payload),

  acceptFollowNotification: (_state: StateInterface, _action: PayloadAction<any>) => {},
  acceptFollowNotificationFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},
  dismissFollowNotification: (_state: StateInterface, _action: PayloadAction<any>) => {},
  dismissFollowNotificationFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},
  followNotificationFailure: (_state: StateInterface, _action: PayloadAction<any>) => {},

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
