import reducers from './auth.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './auth.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const AUTH_KEY = 'auth-store';

const authSlice = createSlice({ name: AUTH_KEY, initialState, reducers });
const authSelector: SelectorInterface = buildSelector(authSlice, initialState);
const authActions = authSlice.actions;
const authState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default authSlice.reducer;
export { AUTH_KEY, authActions, authSelector, authState };
