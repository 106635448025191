import reducers from './users.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState, SelectorInterface } from './users.state';
import { buildSelector } from '@app/utils/common';
import _ from 'lodash';
const USERS_KEY = 'users-store';

const usersSlice = createSlice({ name: USERS_KEY, initialState, reducers });
const usersSelector: SelectorInterface = buildSelector(usersSlice, initialState);
const usersActions = usersSlice.actions;
const usersState: SelectorInterface = Object.keys(initialState).reduce((state, key) => _.set(state, key, key), {});

export default usersSlice.reducer;
export { USERS_KEY, usersActions, usersSelector, usersState };
