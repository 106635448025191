import { ObjectWithStringValues } from '@app/core/models';

export const endpoints: ObjectWithStringValues = {
  getPost: '/feed/read',
  likePost: '/feed/likePost',
  unlikePost: '/feed/unlikePost',
  createComment: '/feed/createComment',
  likeComment: '/feed/likeComment',
  unlikeComment: '/feed/unlikeComment',
  deleteComment: '/feed/deleteComment',
  deletePost: '/feed/delete',
  report: '/moderation/reportContent',
  createPost: '/Feed/CreatePost',
  editPost: '/Feed/EditPost',
  stream: '/feed/stream',
  streamByExtent: '/feed/StreamByExtent',
};
