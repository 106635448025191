import { User } from '@app/core/features/users/user-details.models';
import { convertToCommonDateTime, getPostPhotoUrl, ago } from '@app/utils';

import { Comment } from '@app/core/features/posts/post.models';
import { OBJECT_WITHOUT_ID } from '@app/constants/common';
import { EntitiesCollection } from '../base';

export class Spot {
  id: string;
  name: string;
  description: string;
  species: string;
  speciesId: string;
  speciesPhoto: string;
  spotDateTime: string;
  waterwayId: string;
  waterwayName: string;
  photoId: string;
  photo: string;
  latitude: number;
  longitude: number;
  locationDescription: string;
  lastUpdate: string;
  privacyType: number;
  owner: User;
  likeCount: number;
  likedByUser: boolean;
  commentCount: number;
  commentedOnByUser: boolean;
  feedItemId: string;
  latestComment: Comment;
  postedDateTime: string;
  isBrag: boolean;
  publicGPS: boolean;
  isSpot: boolean;
  shouldShowWaterway: boolean;
  convertedDatePosted: string;
  isSpotEntity: boolean;

  constructor(response: any) {
    this.id = response.SpotId;
    this.name = response.Name;
    this.description = response.Description;
    this.species = response.Species;
    this.speciesId = response.SpeciesId;
    this.speciesPhoto = response.SpeciesPhotoId && getPostPhotoUrl(response.SpeciesPhotoId, 300);
    this.spotDateTime = response.SpotDateTime && convertToCommonDateTime(response.SpotDateTime);
    this.waterwayId = response.WaterwayId;
    this.waterwayName = response.WaterwayName;
    this.photoId = response.PhotoId;
    this.photo = response.PhotoId && getPostPhotoUrl(response.PhotoId, 800);
    this.latitude = response.Latitude;
    this.longitude = response.Longitude;
    this.locationDescription = response.LocationDescription;
    this.lastUpdate = response.LastUpdate && convertToCommonDateTime(response.LastUpdate);
    this.privacyType = response.PrivacyType;
    this.owner = response.Owner && new User(response.Owner);
    this.likeCount = response.LikeCount;
    this.likedByUser = response.LikedByUser;
    this.commentCount = response.CommentCount;
    this.commentedOnByUser = response.CommentedOnByUser;
    this.feedItemId = response.FeedItemId;
    this.latestComment = response.LatestComment && new Comment(response.LatestComment);
    this.postedDateTime = response.PostedDateTime && convertToCommonDateTime(response.PostedDateTime);
    this.isBrag = response.IsBrag;
    this.publicGPS = response.PublicGPS;
    this.isSpot = true;
    this.convertedDatePosted = ago(response.PostedDateTime || response.SpotDateTime);
    this.shouldShowWaterway = response.WaterwayId !== OBJECT_WITHOUT_ID;
    this.isSpotEntity = true;
  }

  static empty() {
    return new Spot({});
  }
}
