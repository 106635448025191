export const metadata: any = {
  HOME1: {
    title: 'Local Fishing Reports, Spots & Fishing Social Network | Fishidy',
    description:
      'Join Fishidy’s waterway-based fishing social network to connect with local anglers' +
      'and find the latest fishing reports, catches and spots in your area!',
    keywords: '',
  },
  SIGN_IN: {
    title: 'Sign in to Your Fishidy Account',
    description: 'The first step in finding the best fishing spots is to sign in to your Fishidy account.',
    keywords: '',
  },
  SIGN_UP: {
    title: 'Sign up for Free and Start Catching More Fish | Fishidy',
    description:
      'Join the map-based social network created by anglers for anglers. ' +
      'Get Started for Free to access local fishing reports and detailed, online waterway maps.',
    keywords: '',
  },
  SIGN_UP_FREE: {
    title: 'Sign up for Free and Start Catching More Fish | Fishidy',
    description:
      'Join the map-based social network created by anglers for anglers. Get Started for ' +
      'Free to access local fishing reports and detailed, online waterway maps.',
    keywords: '',
    author: 'Fishidy, Inc.',
  },
  SIGN_UP_PREMIUM: {
    title: 'Fishing Hot Spots® & Local Fishing Tips: Go Premium | Fishidy',
    description:
      'Upgrade to Premium to unlock proven Fishing Hot Spots®, ' +
      'access mapped underwater structure and catch more fish more often - that’s Fishidy!',
    keywords: '',
  },
  ACTIVITY: {
    title: 'Activity Stream',
  },
  TERMS: {
    title: 'fishidy.com terms and conditions information',
    description: 'All the terms and conditions you ever needed (or wanted) to know about fishidy.com - right here in one place.',
    keywords: '',
  },
  PRIVACY: {
    title: 'fishidy.com privacy policy',
    description:
      'All your questions about Fishidy’s privacy policy are answered here – rest assured we’ve thought ' +
      'of pretty much everything to keep your information safe and secure.',
    keywords: '',
  },
  PAGE_NOT_FOUND: {
    title: 'Page Not Found | Error',
  },
  MESSAGE: {
    title: 'Direct Messages',
    description: '',
    keywords: '',
    author: 'Fishidy, Inc.',
  },
  BRAGGIN_BOARD: {
    title: 'Fishing Photo Contest, Sweepstakes & Giveaways | Braggin’ Board',
    description: 'Submit your best fishing photos for a chance to win a 1-year premium subscription on Fishidy!',
    keywords: '',
    author: '',
  },
  RULES: {
    title: 'Official Rules of the Fishidy Braggin’ Board',
    description:
      'Here are the official rules on becoming weekly champ of the Fishidy Braggin’ Board, and how ' +
      'you can win a complimentary 1-year premium subscription.',
    keywords: '',
    author: '',
  },
  WALL: {
    title: 'The Wall of Winning Catches on Fishidy’s Braggin’ Board',
    description: 'See the top catches posted on Fishidy in the Braggin’ Board Winners Wall - win our fishing photo contest and join them!',
    keywords: '',
    author: '',
  },
  PROFILE: {
    title: 'Manage Your Fishidy Profile Account',
  },
  SITEMAP: {
    title: 'Find your way around Fishidy.com',
    description: 'description needed',
    author: 'Fishidy, Inc.',
  },
  ABOUT: {
    title: 'Background, Benefits & Behind the Scenes | About Fishidy',
    description:
      'Learn more about how Fishidy got started and get a peek behind the scenes of one' +
      ' of the fastest growing fishing social networks that can help you become a better angler.',
    author: 'Fishidy, Inc.',
  },
  RAYMARINE_SYNC: {
    title: 'Raymarine Sync',
    description: 'Find information about Fishidy sync with Raymarine',
    author: 'Fishidy, Inc.',
  },
  FAQ: {
    title: 'Frequently Asked Question & Answers About Fishidy',
    description:
      'Have questions about using Fishidy? Find the answer with our series of commonly a' +
      ' sked questions and answers on how to best use Fishidy.',
    author: 'Fishidy, Inc.',
  },
  FISHING_MOBILE_APP: {
    title: 'Fishidy Fishing App | Lake Maps, Depth Contours, Reports & GPS',
    description:
      'Download Fishidy’s app for access to detailed fishing reports,' +
      ' the best waterway maps, a personal fishing log, real-time weather and so much more!',
    author: 'Fishidy, Inc.',
  },
  FISHING_MAP: {
    title: 'Online Fishing Maps & Charts',
    description:
      'Join Fishidy for free to access online fishing maps & charts loaded ' +
      'with proven information covering thousands of waterways across the U.S.',
    author: 'Fishidy, Inc.',
  },
  MAP: {
    title: '{{ name }} Fishing Reports, Maps & Hot Spots',
    description:
      'Find recent fishing reports, catches, and top anglers on waterways in ' +
      '{{ name }} as you explore interactive fishing maps on Fishidy. Get Started for Free!',
    author: 'Fishidy, Inc.',
  },
};
