import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BaseHelperService } from '@app/core/features/base';
import { accountActions } from './account.slice';
import { Store } from '@ngrx/store';
import { AccountService } from '@app/core/features/account/account.service';

@Injectable()
export class AccountEffects {
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.resetPassword),
      mergeMap(action =>
        this.accountService.resetPassword(action.payload).pipe(
          map(data => {
            this.baseHelper.showSuccessActionToastr(data.Message, 'Reset Password.');
            return accountActions.resetPasswordSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, accountActions.resetPasswordFailure)),
        ),
      ),
    ),
  );

  getNotificationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.getNotificationSettings),
      mergeMap(action =>
        this.accountService.getNotificationSettings(action.payload).pipe(
          map(data => accountActions.getNotificationSettingsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, accountActions.getNotificationSettingsFailure)),
        ),
      ),
    ),
  );

  updateNotificationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.updateNotificationSettings),
      mergeMap(({ payload: { accountId, notifications } }) =>
        this.accountService.updateNotificationSettings(accountId, notifications).pipe(
          map(data => accountActions.updateNotificationSettingsSuccess(data)),
          catchError(error => this.baseHelper.catchError(error, accountActions.updateNotificationSettingsFailure)),
        ),
      ),
    ),
  );

  userDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.userDelete),
      mergeMap(action =>
        this.accountService.userDelete(action.payload).pipe(
          map(data => {
            this.baseHelper.showSuccessActionToastr('Delete account successfully!');
            return accountActions.userDeleteSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, accountActions.userDeleteFailure)),
        ),
      ),
    ),
  );

  resetAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.resetAccount),
      mergeMap(action =>
        this.accountService.resetAccount(action.payload).pipe(
          map(data => {
            this.baseHelper.showSuccessActionToastr(data.Message, 'Reset Account.');
            return accountActions.resetAccountSuccess(data);
          }),
          catchError(error => this.baseHelper.catchError(error, accountActions.resetAccountFailure)),
        ),
      ),
    ),
  );

  constructor(
    private accountService: AccountService,
    protected store: Store<{}>,
    private baseHelper: BaseHelperService,
    protected actions$: Actions,
  ) {}
}
