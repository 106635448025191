import { PayloadAction } from '@reduxjs/toolkit';
import { initialState, StateInterface } from './contest.state';
import { request, success, failure, cleanState, push, update } from '@app/utils/common';
import _ from 'lodash';
export default {
  getLastWinner: (state: StateInterface, _action: PayloadAction<any>) => request(state.getLastWinner),
  getLastWinnerSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getLastWinner, payload),
  getLastWinnerFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getLastWinner, payload),

  getWinners: (state: StateInterface, _action: PayloadAction<any>) => request(state.getWinners),
  getWinnersSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => success(state.getWinners, payload),
  getWinnersFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getWinners, payload),

  getContestEntries: (state: StateInterface, { payload }: PayloadAction<any>) => request(state.getContestEntries, payload),
  getContestEntriesSuccess: (state: StateInterface, { payload }: PayloadAction<any>) => push(state.getContestEntries, payload),
  getContestEntriesFailure: (state: StateInterface, { payload }: PayloadAction<any>) => failure(state.getContestEntries, payload),

  voteEntry: (state: StateInterface, { payload }: PayloadAction<any>) => {
    update(state.getContestEntries, { likedByUser: true, likeCount: payload.likeCount + 1 }, { id: payload.id });
  },
  voteEntrySuccess: (_state: StateInterface) => {},
  voteEntryFailure: (state: StateInterface, { payload }: PayloadAction<any>) => {
    update(state.getContestEntries, { likedByUser: false, likeCount: payload.likeCount - 1 }, { id: payload.id });
  },

  cleanState: (state: StateInterface, { payload }: PayloadAction<any>) => cleanState(state, payload, initialState),
};
