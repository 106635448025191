export class Auth {
  token: string;
  user: User;
  constructor(response: any) {
    this.token = response.Token;
    this.user = new User(response);
  }
}

export class User {
  id: string;
  name: string;
  photoId: string;
  privacy: number;
  accountType: number;
  premiumEnd: any;
  facebookId: string;
  facebookToken: string;

  constructor(response: any) {
    this.id = response.Id;
    this.name = response.Name;
    this.photoId = response.PhotoId;
    this.privacy = response.Privacy;
    this.accountType = response.AccountType;
    this.premiumEnd = response.PremiumEnd;
    this.facebookId = response.FacebookId;
    this.facebookToken = response.FacebookToken;
  }
}
