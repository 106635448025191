export interface RequestConfig {
  url: string;
  baseUrl?: string;
  params?: any;
  query?: any;
  body?: any;
}

export interface BaseRequestConfig extends RequestConfig {
  method: string;
}
