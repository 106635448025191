import { ResponseApi, initResult } from '@app/utils';
export interface StateInterface {
  getNotifications?: ResponseApi<any>;
  getNotificationCount?: ResponseApi<any>;
  getMessageCount?: ResponseApi<any>;
}

export interface SelectorInterface {
  getNotifications?: any;
  getNotificationCount?: any;
  getMessageCount?: any;
}

export const initialState: StateInterface = {
  getNotifications: initResult,
  getNotificationCount: initResult,
  getMessageCount: initResult,
};
